import "antd/dist/antd.css";
import React from "react";
import {
  Table,
  Card,
  Col,
  Row,
  Input,
  Select,
  Tabs,
  notification,
  Spin,
} from "antd";
import BasicInfo from "./BasicInfo";
import PurchaseOrderTable from "./PurchaseOrderTable";
import "./ProjectDetails.scss";
import { useHistory, useParams } from "react-router-dom";
import { Fragment, useMemo } from "react";
import arrow from "../../../assets/images/arrow.png";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { useState, useEffect, useCallback } from "react";
// import SoldSummary from "./SoldSummary";
// import EarnedSummary from "./EarnedSummary";
// import EfficiencySummary from "./EfficiencySummary";
import type { ColumnsType } from "antd/es/table";
import { Button } from "antd";
import {
  convertNumber,
  isAdmin,
  convertCurrency,
  errorNotification,
} from "../../../utils/util";
import { useSelector } from "react-redux";
import { projectDetails } from "../../../utils/enums";
import Loader from "../../../components/Loader/Loader";
import { SupersetEmbedComponent } from "../../../components";
import HeatMapComponent from "../../../components/HeatMapComponent/HeatMapComponent";
import { Empty } from "antd";
import SessionAudit from "../../../components/AuditModal/SessionAudit";
import { Layout, Menu } from "antd";
import { getWeek } from "date-fns";

interface IProjectOwner {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  owner_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
interface IBUHeads {
  id: number;
  is_active: boolean;
  start_date: Date;
  end_date: Date;
  project_id: number;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}
const ProjectDetails = () => {
  const { Option } = Select;
  let history = useHistory();
  const { TabPane } = Tabs;
  const [projectData, setProjectData] = useState([]);
  const [projectName, setProjectName] = useState();
  const [billabaleGeoOption, setBillableGeoOption] = useState();
  const [customerName, setCustomerName] = useState();
  const [associatedBU, setAssociatedBU] = useState();
  const [associatedBuId, setAssociatedBuId] = useState();
  const [isBillable, setIsBillable] = useState("");
  const [projectCode, setProjectCode] = useState();
  const [projectList, setProjectList] = useState([]);
  // const [stus, setStus] = useState();
  // const [total_sold_base, setTotalSoldBase] = useState(0);
  // const [totalDirectExpense, setTotalDirectExpense] = useState(0);
  const [futureValue, setfutureValue] = useState(0);
  const [this_month_value, setThisMonthValuet] = useState(null);
  const [managers, setManagers] = useState("");
  const [attachmentIds, setAttachmentIds] = useState([]);
  // const [revenue, setRevenue] = useState(null);
  // const [expended, setExpended] = useState();
  const [archiveStatus, setArchiveStatus] = useState();
  const [locationData, setLocationData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentYear, setCurrentYear] = useState("");
  const [projectOwners, setProjectOwners] = useState<IProjectOwner[]>([]);
  const [buHeads, setBUHeads] = useState<IBUHeads[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [directExpense, setDirectExpense] = useState(0);
  // const [totalExpense, setTotalExpense] = useState(0);
  const [userid, getuserid] = useState();
  const [rolesData, setRolesData] = useState([]);
  const [projectAccessRolesData, setProjectAccessRolesData] = useState([]);
  const [roleHistory, setRolesHistory] = useState({});
  const [rolesEditAccess, setRolesEditAccess] = useState(false);
  const [currentFinacialMonth, setCurrentFinacialMonth] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [allMonthData, setAllMonthsData] = useState([]);
  const [filteredMonth, setFilteredMonth] = useState({});
  const [projectDetailsData, setProjectDetailsData] = useState({});
  const [projectReviews, setProjectReviews] = useState([]);
  const [reviewDataAndType, setReviewDataAndType] = useState({
    data: [],
    type: "",
  });
  const [selectedReview, setSelectedReview] = useState({ id: 0, interval: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [auditMapLoader, setAuditMapLoader] = useState(true);
  const [xField, setxField] = useState("");
  const [heightOfHeatMap, setHeightOfHeatMap] = useState("");
  const [heightOfHeatMapContainer, setHeightOfHeatMapContainer] = useState("");
  const { Sider, Content } = Layout;
  const [colors, setColors] = useState([]);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedAudit, setSelectedAudit] = useState({
    week_no: "",
    year: "",
    access_to_all: "",
    month: "",
    customer_id: "",
    customer_name: "",
    user: {
      firstName: "",
      lastName: "",
    },
    quarterly: "",
    entity: "",
  });
  const [func_type, setFunc_type] = useState("");
  const [activeChart, setActiveChart] = useState("Weekly");
  const [refreshKey, setRefreshKey] = useState(0);
  const refreshParent = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const currencyRate = useSelector(
    (store: any) => store?.currencyRate?.data?.currencyRate
  );

  var token = getToken();
  const monthOrder = {
    January: 1,
    February: 2,
    March: 3,
    April: 4,
    May: 5,
    June: 6,
    July: 7,
    August: 8,
    September: 9,
    October: 10,
    November: 11,
    December: 12,
  };

  let { id }: any = useParams();
  const getProjectReviews = useCallback(async () => {
    try {
      const res = await AxiosConfig.get(
        "/reviewSettings/reviews?project_id=" + id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      let reviews = res?.data?.data?.reviewData;

      const filteredDataOfCurrentProject = reviews.filter((review) => {
        return review.project_ids?.includes(+id);
      });
      setProjectReviews(filteredDataOfCurrentProject);
    } catch (error) {
      console.log(error);
    } finally {
      setAuditMapLoader(false);
    }
  }, [id]);

  useEffect(() => {
    getUser();
    getMonthsId();
    getAllUsers();
  }, []);

  const getUser = async () => {
    try {
      const response = await AxiosConfig.get("users/current", {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.data && response.data.data && response.data.data.user) {
        const user_id = response.data.data.user.id;
        getuserid(user_id);
      } else {
        console.error("User data not found in the API response");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const getAllUsers = async () => {
    try {
      const response = await AxiosConfig.get("/users/all-users/details", {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setAllUsers(response?.data?.users);
      }
    } catch (error) {
      console.error("Dropdown users data fetch error", error);
    }
  };

  const getProjectDetails = async (archiveStatus: any) => {
    try {
      if (archiveStatus != undefined && userid) {
        const res = await AxiosConfig.get(
          "/projects/projectList/names?user_id=" + userid + "&archive=false",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        let projects = res?.data?.data?.projects;
        projects = projects.sort((a: any, b: any) => {
          var a1 = a.project_code.replace("STP_", "");
          var b1 = b.project_code.replace("STP_", "");
          return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
        });
        setProjectList(projects || []);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getMonthsId = async () => {
    try {
      const res = await AxiosConfig.get("financialmonths", {
        headers: { Authorization: `Bearer ${token}` },
      });

      const monthsData = res?.data?.data?.months;
      setAllMonthsData(monthsData);
      const currentMonth = monthsData.find(
        (month: any) => month.status === "1"
      );

      if (currentMonth) {
        setCurrentMonth(currentMonth.month);
        setCurrentYear(currentMonth.year);
        setFilteredMonth(currentMonth);
        const currentFiscalYear =
          String(currentMonth.month).substring(0, 3) +
          "' " +
          String(currentMonth.year).substring(2, 4);
        localStorage.setItem("currentFiscalYear", currentFiscalYear);
      } else {
        console.error("Current month not found.");
      }
    } catch (error) {
      console.error("Error fetching months: ", error);
    }
  };

  useEffect(() => {
    tableData();
  }, [currencyRate]);

  const tableData = async () => {
    try {
      const response = await AxiosConfig.get("/projects/projectSummary/" + id, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const summaries = response.data.data;

      const formattedData = summaries.map((summary) => {
        return {
          Month: summary.month,
          Year: summary.year,
          Month_Year1: `${summary.month.substr(0, 3)} ${summary.year}`,
          Revenue_Value1: convertCurrency(summary?.Revenue_Value, currencyRate),
          stus_executed1: convertNumber(summary.stus_executed, 2),
          Stus_allocated1: convertNumber(summary.allocated_stus, 2),
          stus_expended1: convertNumber(summary.stus_expended, 2),
        };
      });

      setProjectData(formattedData);
    } catch (error) {
      console.error(error);
    }
  };

  const notify = (message: string, description?: string, color?: string) => {
    notification.open({
      message,
      description,
      style: { color },
    });
  };

  const handleChange = (value: any) => {
    localStorage.setItem("projectsId", value);
    history.push("/projects/" + value);
    window.location.reload();
  };

  const onClickRow = () => {
    history.push("/allocations/" + id);
  };
  const getData = useMemo(
    () => async () => {
      try {
        if (userid) {
          const res = await AxiosConfig.get(
            `projects/${id}?user_id=${userid}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          if (res.data?.status?.code === 401) {
            notify(
              res.data.status.message,
              "Unauthorized access. You do not have access for this project.",
              "red"
            );
            setTimeout(function () {
              history.push("/projects/");
            }, 5000);
          }

          if (res.data?.data?.project) {
            setProjectDetailsData(res.data.data.project || {});
            setBUHeads(res.data.data.BUHeads);
            setProjectName(res.data.data.project.name);
            setCustomerName(res.data.data.project.customer.name);
            setAssociatedBU(res.data.data.project.department.name);
            setAssociatedBuId(res.data.data.project.department.id);
            setProjectCode(res.data.data.project.project_code);
            setThisMonthValuet(res.data.data.project.this_month_base);
            setManagers(res.data.data.project?.users);
            setArchiveStatus(res.data.data.project.archive_status);
            setLocationData(res.data.data.project.Geography.location);
            setBillableGeoOption(
              res.data.data.project.billableentity.entity_name
            );
            setProjectAccessRolesData(res.data?.data?.projectAccessRoles);
            setRolesHistory(res.data?.data?.roleHistory || {});
            setRolesData(res.data?.data?.roles);
            const isProjectOwnerPresent = res.data?.data?.roles?.find(
              (rol) => rol.role_name === "Project Owner"
            );
            const isUserProjectOwner =
              isProjectOwnerPresent?.user_id === userid;
            setRolesEditAccess(
              isUserProjectOwner || isAdmin() || res.data?.data?.isBUHead
                ? true
                : false
            );
            setCurrentFinacialMonth(res?.data?.data?.finacialMonth);
            if (res.data.data.project.billable_option == true) {
              setIsBillable("True");
            } else if (res.data.data.project.billable_option == false) {
              setIsBillable("False");
            } else {
              setIsBillable("Not Selected");
            }
            // let direct_expenses = 0;
            // res.data.data.project.PurchaseOrders.forEach((exp: any) => {
            // direct_expenses += exp.direct_expense;
            // });
            // setTotalDirectExpense(direct_expenses);
            setAttachmentIds(
              res.data.data.project.projectattachments.map(
                (row: { id: any; attachment_id: any }) => ({
                  id: row.id,
                  attachment_id: row.attachment_id,
                })
              )
            );
            setProjectOwners(res.data.data.project.ProjectOwners);
            await getProjectDetails(res.data.data.project.archive_status);
          }
          setfutureValue(res.data?.data?.futureValue);
          setIsLoading(false);
        }
      } catch (err) {
        console.error("Error fetching data: ", err);
        setIsLoading(false);
      }
    },
    [userid]
  );

  useEffect(() => {
    getData();
  }, [userid]);

  useEffect(() => {
    // Calling handleTabChange with the default key (while loading first time)
    handleTabChange("1");
  }, []);

  const archive_status = () => {
    const status = archiveStatus;
    if (status == false) {
      console.log("project is active");
    } else {
      return "Archived Project";
    }
  };

  const futureValueData = () => {
    const value = futureValue ?? 0;
    return convertCurrency(value, currencyRate);
  };

  const currentMonthValue = () => {
    const currentMonth = this_month_value ?? 0;
    return convertCurrency(currentMonth, currencyRate);
  };

  // Function to determine the number of weeks in a year
  function getNumberOfWeeks(year) {
    var month = 11,
      day = 31,
      week;

    // Find week that 31 Dec is in. If is first week, reduce date until
    // get previous week.
    do {
      const d = new Date(year, month, day--);
      week = getWeekNumber(d)[1];
    } while (week == 1);

    return week;
  }

  // Function to get the ISO week number of a date
  function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(+d);
    d.setHours(0, 0, 0, 0);
    // Set to nearest Thursday: current date + 4 - current day number
    // Making Sunday's day number 7
    d.setDate(d.getDate() + 4 - (d.getDay() || 7));
    // Get first day of year
    var yearStart = new Date(d.getFullYear(), 0, 1);
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil(
      ((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7
    );
    // Return array of year and week number
    return [d.getFullYear(), weekNo];
  }

  // Function to add missing weeks
  function addMissingWeeks(data) {
    // Get unique years from the data
    const years = [...new Set(data.map((item) => item.year))];
    if (years.length <= 2) setHeightOfHeatMap("3.5rem");
    else setHeightOfHeatMap(years.length * 2 + "rem");
    setHeightOfHeatMapContainer(years.length * 2 + 2 + "rem");
    // Generating data for missing weeks
    years.forEach((year) => {
      const numberOfWeeks = getNumberOfWeeks(year);
      for (let week = 1; week <= numberOfWeeks; week++) {
        // Check if the week for the current year is missing
        if (
          !data.some(
            (item) => item.year == year && item.week_no == week.toString()
          )
        ) {
          // Adding the missing week with average_score 0
          data.push({
            week_no: week.toString(),
            year: year,
            color_code: "#C0C0C0",
            average_score: -1,
            color_value: { value: null, color_code: "#C0C0C0" },
          });
        }
      }
    });

    // Sorting the data by year and week_no
    data.sort((a, b) => {
      if (a.year === b.year) {
        return a.week_no - b.week_no;
      }
      return a.year - b.year;
    });

    return data;
  }

  function addMissingMonthsData(data) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const truncatedMonths = months;
    // Converting existing month data to truncated string
    const convertedData = data.map((entry) => ({
      ...entry,
      month: truncatedMonths[entry.month - 1],
    }));

    const newData = [...convertedData];

    convertedData.forEach((entry) => {
      const year = entry.year;

      for (let i = 1; i <= 12; i++) {
        if (
          !convertedData.some(
            (e) => e.year === year && e.month === truncatedMonths[i - 1]
          )
        ) {
          newData.push({
            year: year,
            average_score: -1,
            color_code: "#C0C0C0",
            month: truncatedMonths[i - 1],
          });
        }
      }
      newData.sort((a, b) => {
        const yearA = parseInt(a.year, 10);
        const yearB = parseInt(b.year, 10);
        const monthA = monthOrder[a.month];
        const monthB = monthOrder[b.month];

        if (yearA !== yearB) {
          return yearA - yearB;
        } else {
          return monthA - monthB;
        }
      });
    });

    return newData;
  }

  const addMissingQuarterlyData = (data) => {
    const quarters = ["JFM", "AMJ", "JAS", "OND"];
    // Creating a map to group data by year
    const dataByYear = data?.reduce((acc, item) => {
      if (!acc[item.year]) {
        acc[item.year] = {};
      }
      acc[item.year][item.quarterly] = item;
      return acc;
    }, {});

    const result: any[] = [];

    for (const year in dataByYear) {
      const yearData = dataByYear[year];
      quarters?.forEach((quarter) => {
        // If the quarter is not in the year's data, adding a new entry with average score 0
        if (!yearData[quarter]) {
          result?.push({
            year: year,
            average_score: -1,
            quarterly: quarter,
            x_field: "quarterly",
            color_code: "#C0C0C0",
          });
        } else {
          // If the quarter is in the year's data, adding the existing entry
          result?.push(yearData[quarter]);
        }
      });
    }

    return result;
  };

  const getReviewData = async (selectedReview) => {
    setAuditMapLoader(true);
    try {
      const res = await AxiosConfig.get(
        "/reviewSettings/reviews/projects/" +
          id +
          "?review_id=" +
          selectedReview.id,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      let sortedData = res?.data?.data?.reviewData;
      let colors = res?.data?.data?.colorData;
      setColors(colors);
      setxField(sortedData[0]?.x_field);
      setSelectedReview(selectedReview);

      if (selectedReview.interval == "Weekly") {
        // adding missing weeks data
        sortedData = addMissingWeeks(sortedData)?.map((item) => ({
          ...item,
          week_no: "W" + item?.week_no?.toString(),
          week_name: "week" + item?.week_no?.toString(),
        }));
      } else if (selectedReview?.interval == "Monthly") {
        sortedData = addMissingMonthsData(sortedData);
        const years = [...new Set(sortedData.map((item) => Number(item.year)))];
        setHeightOfHeatMap(years.length * 4 + "rem");
        setHeightOfHeatMapContainer(years.length * 2 + 2 + "rem");
      } else if (selectedReview?.interval == "Quarterly") {
        sortedData = addMissingQuarterlyData(sortedData);
        const years = [...new Set(sortedData.map((item) => Number(item.year)))];
        setHeightOfHeatMap(years.length * 4 + "rem");
        setHeightOfHeatMapContainer(years.length * 2 + 2 + "rem");
      }
      const currentYear = new Date().getFullYear();
      sortedData = sortedData
        ?.filter((item) => item.year <= currentYear)
        .map((item) => ({
          ...item,
          year: item?.year?.toString(),
          floor_score:
            item.average_score >= 0 ? Math.floor(item.average_score) : null,
          entity: "Project",
          color_value: { value: item.floor_score, color: item.color_code },
        }));
      setReviewDataAndType({
        data: sortedData,
        type: selectedReview?.interval,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setAuditMapLoader(false);
      refreshParent();
    }
  };

  const handleReviewChange = (event) => {
    setAuditMapLoader(true);
    let filteredProjectReviews = projectReviews?.filter(
      (review: any) => review.interval === activeChart
    );
    if (filteredProjectReviews.length > 0) {
      const selectedReview = filteredProjectReviews[event.key];
      getReviewData(selectedReview);
    } else {
      getReviewData({ data: [], type: "" }); // this is for making page to show 'no data' on trvaersing through different reviews with no reviews
    }
  };

  function extractNumber(str) {
    // Use a regular expression to match the digits in the string
    const match = String(str).match(/\d+/);

    // Convert the matched digits to an integer
    const number = match ? parseInt(match[0], 10) : null;

    return number;
  }

  interface DataType {
    Month_Year: string;
    Month: string;
    Year: string;
    Month_Status: any;
    Revenue_Value: string;
    Revenue_base: string;
    Stus_allocated: string;
    Stus_earned: string;
    Stus_utilized: string;
    stus_executed: string;
    stus_expended: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: "MONTH",
      dataIndex: "Month_Year1",
      key: "Month_Year1",

      sorter: (a, b) => {
        if (a.Month === "MAG" || b.Month === "MAG") {
          return -1;
        }
        return (
          parseInt(a.Year) - parseInt(b.Year) ||
          monthOrder[a.Month] - monthOrder[b.Month]
        );
      },
      sortOrder: "ascend",
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "EARNED",
      className: "earned_Data",
      dataIndex: "Revenue_Value1",
      key: "Revenue_Value1",
    },

    {
      title: "EARNED STU'S",
      className: "exec_Data",
      dataIndex: "stus_executed1",
      key: "stus_executed1",
    },

    {
      title: "PLANNED STU'S",
      className: "alloc_Data",
      dataIndex: "Stus_allocated1",
      key: "Stus_allocated1",
    },
    {
      title: "UTILIZED STU'S",
      className: "expended_Data",
      dataIndex: "stus_expended1",
      key: "stus_expended1",
    },
  ];

  function isCurrentQuarter(quarterIdentifier) {
    const currentMonth = new Date().getMonth() + 1;

    if (currentMonth >= 1 && currentMonth <= 3) {
      return quarterIdentifier == "JFM";
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      return quarterIdentifier == "JFM" || quarterIdentifier == "AMJ";
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      return (
        quarterIdentifier == "JFM" ||
        quarterIdentifier == "AMJ" ||
        quarterIdentifier == "JAS"
      );
    } else if (currentMonth >= 10 && currentMonth <= 12) {
      return (
        quarterIdentifier == "JFM" ||
        quarterIdentifier == "AMJ" ||
        quarterIdentifier == "JAS" ||
        quarterIdentifier == "OND"
      );
    }
  }
  const handleCalendarAuditRecordClick = (data) => {
    const todayWeek = getWeek(new Date());
    if (data.average_score < 0) {
      setFunc_type("add");
    } else {
      setFunc_type("update");
    }
    if (Number(data.year) > new Date().getFullYear()) {
      errorNotification(
        "Sorry, data entry for the upcoming year is not allowed"
      );
      return;
    }
    if (
      selectedReview.interval == "Weekly" &&
      Number(extractNumber(data.week_no)) > todayWeek &&
      data.year >= new Date().getFullYear()
    ) {
      errorNotification(
        "Sorry, data entry for the upcoming week is not allowed"
      );
      return;
    }
    if (
      selectedReview.interval == "Monthly" &&
      data.year >= currentYear &&
      monthOrder[data.month] > new Date().getMonth() + 1
    ) {
      errorNotification(
        "Sorry, data entry for the upcoming month is not allowed"
      );
      return;
    }
    if (
      selectedReview.interval == "Quarterly" &&
      ((data.year === currentYear && !isCurrentQuarter(data.quarterly)) ||
        data.year > currentYear)
    ) {
      errorNotification(
        "Sorry, data entry for the upcoming quarter is not allowed"
      );
      return;
    }
    setIsModalOpen(true);
    setSelectedAudit(data);
    getReviewData(selectedReview);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    getReviewData(selectedReview); // *this helps in reflecting data after edit
  };

  useEffect(() => {
    getProjectReviews();
  }, [activeChart]);

  useEffect(() => {
    handleReviewChange({ key: 0 });
  }, [activeChart, projectReviews]); // project Reviews to make the heatmap load on default when component loads
  // active chart to make heatmap load automatically when tab changes

  const handleTabChange = (key) => {
    if (key === "1") setActiveChart("Weekly");
    if (key === "2") setActiveChart("Monthly");
    else if (key === "3") setActiveChart("Quarterly");
    setAuditMapLoader(true);
    setSelectedKey("0");
    refreshParent();
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Fragment>
          <Row gutter={24}>
            <Col xl={6} xs={24}>
              <span className="back-button" onClick={() => history.goBack()}>
                <img src={arrow} />
                {projectDetails.textForBack}
              </span>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={12} xs={24}>
              <Input.Group className="project-select">
                <Select
                  showSearch
                  className="searchableDropdown width-550"
                  onChange={handleChange}
                  placeholder={`${projectCode} : ${projectName}`}
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {projectList.map(
                    (row: { name: any; id: any; project_code: any }) => (
                      <Option key={row.id} value={row.id}>
                        {row.project_code + ": " + row.name}
                      </Option>
                    )
                  )}
                </Select>
              </Input.Group>
              <Button
                id="button1"
                onClick={onClickRow}
                className="archivDivStyle"
              >
                {projectDetails.ButtonForProjectAllocationDetail}
              </Button>
            </Col>
            <Col
              className="futureSales"
              xl={12}
              xs={24}
              style={{ display: "grid" }}
            >
              <div className="archivDivStyle">
                <h3 className="archive_status">{archive_status()}</h3>
              </div>

              <h3 className="archivDivStyle">
                {projectDetails.textForFutureValue} {futureValueData()}
              </h3>
              <h3 className="archivDivStyle">
                {String(currentMonth).substring(0, 3) + "' "}
                {String(currentYear).substring(2, 4)}{" "}
                {projectDetails.textForCurrentValue} {currentMonthValue()}
              </h3>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={6} xs={24}>
              <Card
                className="basic-info"
                bordered={false}
                style={{ height: "max-content" }}
              >
                <BasicInfo
                  projectNameParent={projectName}
                  billablaeGeoOption={billabaleGeoOption}
                  customerNameParent={customerName}
                  associatedBUParent={associatedBU}
                  projectCodeParent={projectCode}
                  managersParent={managers}
                  attachmentIds={attachmentIds}
                  isBillable={isBillable}
                  locationNameParent={locationData}
                  flag={false}
                  projectOwners={projectOwners}
                  buHeads={buHeads}
                  rolesData={rolesData}
                  projectAccessRolesData={projectAccessRolesData}
                  isNotAllocationPage={true}
                  roleHistory={roleHistory}
                  rolesEditAccess={rolesEditAccess}
                  currentFinacialMonth={currentFinacialMonth}
                  allUsers={allUsers}
                  projectDetails={projectDetailsData}
                ></BasicInfo>
              </Card>
            </Col>
            <Col xl={18} xs={24}>
              <div>
                <Card
                  title="Efficiency Summary"
                  bordered={false}
                  className="basic-info"
                  style={{ height: "95%" }}
                >
                  <Tabs defaultActiveKey="1">
                    <TabPane tab="Project view" key="1">
                      <SupersetEmbedComponent
                        pageType="project_view"
                        isDepartmentEnabled={false}
                        isSingleProject={true}
                        projectId={id}
                      />
                    </TabPane>
                    <TabPane tab="Table" key="2">
                      <Table
                        columns={columns}
                        dataSource={projectData}
                        bordered
                        className="projectsTable"
                        pagination={{ pageSize: 10 }}
                      />
                    </TabPane>
                    <TabPane tab="Audit" key="3">
                      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
                        <TabPane tab="Weekly" key="1"></TabPane>
                        <TabPane tab="Monthly" key="2"></TabPane>
                        <TabPane tab="Quarterly" key="3"></TabPane>
                      </Tabs>

                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Layout
                          style={{
                            height: "19rem",
                          }}
                        >
                          <Sider
                            width={100}
                            style={{
                              position: "absolute",
                              width: "150px !important",
                              minWidth: "100px !important",
                              maxWidth: "180px !important",
                              height: "19rem",
                              backgroundColor: "#485359",
                              margin: "0px",
                              padding: "2px",
                            }}
                          >
                            <Menu
                              selectedKeys={[selectedKey]} // Set the selected key
                              style={{
                                height: "inherit",
                                backgroundColor: "#485359",
                              }}
                              theme="dark"
                              mode="inline"
                              defaultSelectedKeys={["0"]}
                              onClick={(e) => setSelectedKey(e.key)}
                            >
                              {projectReviews
                                ?.filter(
                                  (review: any) =>
                                    review.interval === activeChart
                                )
                                .map((review: any, index: any) => (
                                  <Menu.Item
                                    defaultChecked={index === 0}
                                    style={{ color: "white", fontSize: "10px" }}
                                    key={index}
                                    onClick={handleReviewChange}
                                  >
                                    {review.review_type}
                                  </Menu.Item>
                                ))}
                            </Menu>
                          </Sider>
                          <Layout>
                            <Content>
                              <div
                                className="project-details-audit-tab-container"
                                style={{
                                  height: heightOfHeatMapContainer,
                                  minHeight: "18rem",
                                  alignItems: "center",
                                  overflowX: "auto",
                                }}
                              >
                                {auditMapLoader && (
                                  <Spin
                                    size="large"
                                    className="project-deatils-audit-loader"
                                  />
                                )}
                                {(!auditMapLoader &&
                                  reviewDataAndType?.data?.length === 0 &&
                                  !isModalOpen) ||
                                (!projectReviews && !isModalOpen) ? (
                                  <Empty
                                    style={{
                                      minHeight: "10rem",
                                    }}
                                    description="No Data"
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                  />
                                ) : (
                                  <div
                                    className="project-details-audit-map-container"
                                    style={{
                                      height: heightOfHeatMap,
                                      minHeight: "6rem",
                                      display: "inline-block",
                                      width:
                                        activeChart.toLowerCase() == "weekly"
                                          ? "1000px"
                                          : "",
                                    }}
                                  >
                                    {!auditMapLoader &&
                                      reviewDataAndType?.data && (
                                        <HeatMapComponent
                                          key={refreshKey}
                                          data={reviewDataAndType.data}
                                          xField={xField}
                                          id={reviewDataAndType.type}
                                          colors={colors}
                                          handleCalendarAuditRecordClick={
                                            handleCalendarAuditRecordClick
                                          }
                                        />
                                      )}
                                    {isModalOpen && (
                                      <SessionAudit
                                        response_group_id={selectedReview.id}
                                        group_id={selectedReview.id}
                                        project_id={id}
                                        week_no={extractNumber(
                                          selectedAudit.week_no
                                        )}
                                        year={selectedAudit.year}
                                        project_name={projectName}
                                        project_code={projectCode}
                                        func_type={func_type}
                                        accessToAll={
                                          selectedAudit.access_to_all
                                        }
                                        is_month_report={
                                          selectedReview.interval == "Monthly"
                                        }
                                        month_no={
                                          monthOrder[selectedAudit.month]
                                        }
                                        month_name={selectedAudit.month}
                                        is_quarterly_report={
                                          selectedReview.interval == "Quarterly"
                                        }
                                        quarterly_type={selectedAudit.quarterly}
                                        customer_id={selectedAudit.customer_id}
                                        customer_name={
                                          selectedAudit.customer_name
                                        }
                                        recorded_by={`${
                                          selectedAudit.user?.firstName
                                            ? selectedAudit.user?.firstName
                                            : ""
                                        } ${
                                          selectedAudit.user?.lastName
                                            ? selectedAudit.user?.lastName
                                            : ""
                                        }`}
                                        onClose={handleModalCancel}
                                        entity={selectedAudit.entity}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </Content>
                          </Layout>
                        </Layout>
                      </div>
                    </TabPane>
                  </Tabs>
                </Card>
              </div>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={8} xs={24}>
              {/* <Card bordered={false}>
                <SoldSummary
                  parentStus={stus}
                  parentSales={total_sold_base}
                  parentAvg={total_sold_base - totalDirectExpense}
                />{" "}
              </Card> */}
            </Col>
            <Col xl={8} xs={24}>
              {/* <Card bordered={false}> */}
              {/* <EarnedSummary
                  parentRevenue={revenue}
                  parentExpended={expended}
                  directExpense={directExpense}
                  totalExpense={totalExpense}
                /> */}
              {/* </Card> */}
            </Col>
            <Col xl={8} xs={24}>
              {/* <Card bordered={false}> */}
              {/* <EfficiencySummary /> */}
              {/* </Card> */}
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xl={24} xs={24}>
              <Card className="PurchaseOrderTable" bordered={false}>
                <PurchaseOrderTable
                  archive_status={archiveStatus}
                  associatedBuId={associatedBuId}
                  ProjectManagers={managers}
                  ProjectName={projectName}
                  ProjectOwnerId={projectOwners}
                  rolesEditAccess={rolesEditAccess}
                  filteredMonth={filteredMonth}
                  allMonthData={allMonthData}
                />
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </>
  );
};

export default ProjectDetails;
