import RecordRevenue from "./RecordRevenue";
import RecordRetainer from "./RecordRetainer";

const Recognition = (props: {
  individualPoType?: any;
  RecognitionId?: any;
  newMonth?: any;
  newMonthId?: any;
  newYear?: any;
  onClose?: any;
  newitems?: any;
  filteredMonths?: any;
  currencyName?: any;
  currencySymbol?: any;
  selectedCurrencyId?: any;
  currency_rate?: any;
  archiveStatus?: any;
  directExpense?: number;
  rolesEditAccess:any;
}) => {
  return (
    <>
      {props.individualPoType == 1 ? (
        <RecordRevenue
          poId={props.RecognitionId}
          currentMonth={props.newMonth}
          currentMonthId={props.newMonthId}
          activeYear={props.newYear}
          close={props.onClose}
          monthListFiltered={props.filteredMonths}
          selectedCurrencyId={props.selectedCurrencyId}
          currencyName={props.currencyName}
          currencySymbol={props.currencySymbol}
          archiveStauts={props.archiveStatus}
          currency_rate={props.currency_rate}
          directExpense={props.directExpense}
          rolesEditAccess={props.rolesEditAccess}
        />
      ) : null}
      {props.individualPoType == 3 || props.individualPoType == 2 ? (
        <RecordRetainer
          poId={props.RecognitionId}
          currentMonth={props.newMonth}
          currentMonthId={props.newMonthId}
          activeYear={props.newYear}
          close={props.onClose}
          valueSold={props.newitems.value}
          monthListFiltered={props.filteredMonths}
          currencyName={props.currencyName}
          currencySymbol={props.currencySymbol}
          selectedCurrencyId={props.selectedCurrencyId}
          currency_rate={props.currency_rate}
          archiveStauts={props.archiveStatus}
          directExpense={props.directExpense}
          rolesEditAccess={props.rolesEditAccess}
        />
      ) : null}
    </>
  );
};
export default Recognition;