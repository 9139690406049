import { combineReducers } from "redux";
import getCurrencyReducer from "./getCurrencyReducer";
import getCustomerCategoryReducer from "./getCustomerCategoryReducer";
import getLocationReducer from "./getLocationReducer";
import getRateReducer from "./getRateReducer";
import getSnapShotReducer from "./getSnapShotReducer";
import getBaseCurrencyReducer from "./getBaseCurrencyReducer";
import getCustomerClassificationReducer from "./getCustomerClassificationReducer";
import getFinYearReducer from "./getFinYearReducer";
import getAllCustomersReducer from "./getAllCustomersReducer";
import getEditCustomerClassifcationReducer from "./getEditCustomerClassifcationReducer";
import getCustomerClassifcationTypesReducer from "./getCustomerClassifcationTypesReducer";
import getSecretPassword from "./getSecretPasswordReducer";
import getAllInvoice from "./getAllInvoiceReducer";
import { getDashboardReducer } from "./getDashboardReducer";
import getDepartmentReducer  from "./getDepartmentReducer";
import getBandsReducer  from "./getBandsReducer";
import getSkillsReducer  from "./getSkillsReducer";
import getCurrencyRateReducer  from "./getCurrencyRateReducer";

const appReducer = combineReducers({
  //Add Your Reducers here
  locations: getLocationReducer,
  categories: getCustomerCategoryReducer,
  snapshot: getSnapShotReducer,
  currency: getCurrencyReducer,
  rate: getRateReducer,
  baseCurrency: getBaseCurrencyReducer,
  customerClassification: getCustomerClassificationReducer,
  finYear: getFinYearReducer,
  allCustomers: getAllCustomersReducer,
  editCustomerClassifcation: getEditCustomerClassifcationReducer,
  customerClassifcationTypes: getCustomerClassifcationTypesReducer,
  secretePassword: getSecretPassword,
  allInvoice: getAllInvoice,
  dashboard: getDashboardReducer,
  departments:getDepartmentReducer,
  bands:getBandsReducer,
  skills:getSkillsReducer,
  currencyRate:getCurrencyRateReducer

});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
