import { Row, Col, Button, Divider, Dropdown, Menu, Typography } from "antd";
import { useEffect, useState, useRef } from "react";
import { AxiosConfig } from "../../../ApiConfig";
import { getToken } from "../../../auth";
import { Formik } from "formik";
import * as yup from "yup";
import ButtonReuse from "../../../components/Buttons/Button";
import EditImage from "../../../assets/images/edit.png";
import { Upload, Modal, notification, Input, Image } from "antd";
import {
  PlusOutlined,
  DownOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import UploadAttachment from "./UploadAttachment";
import EditPoAttachmentsRecordRetainer from "./EditPoAttachmentsForRecordRetainer";
import ViewPoAttachment from "./ViewPoAttachments";
import { saveAs } from "file-saver";
import NoData from "../../../assets/images/NoData.png";
import Delete from "../../../assets/images/delete.png";
import pdfPlaceholderImage from "../../../assets/images/pdfPlaceHolder.png";
import xlsxPlaceholderImage from "../../../assets/images/xlsx_thumbnail.png";
import docxPlaceholderImage from "../../../assets/images/docximage.png";
import "./RecordRetainer.scss";
import { recordRetainer } from "../../../utils/enums";
import { useSelector } from "react-redux";
import {
  convertNumberToAmounts,
  formatCurrencyValueDecimal,
  preventMinus,
  convertCurrency,
} from "../../../utils/util";
import Loader from "../../../components/Loader/Loader";

const { TextArea } = Input;
const { Text } = Typography;

const RecordRetainer = (props: {
  poId?: any;
  currentMonth?: any;
  currentMonthId?: any;
  activeYear?: any;
  close?: any;
  valueSold?: any;
  months?: any;
  monthListFiltered?: any;
  currencyName?: any;
  currencySymbol?: any;
  selectedCurrencyId?: any;
  currency_rate?: any;
  archiveStauts?: any;
  directExpense?: number;
  adjustedAmounts?: number;
  rolesEditAccess:any;
}) => {
  const { rolesEditAccess } = props;
  const [earnings, setEarnings] = useState([]);
  const [attachments_id, setAttachmentId] = useState();
  const [editAttachment_id, setEditAttachmentId] = useState();
  const fileData: any = useRef({});
  const monthsArray: any = useRef([]);
  const [isEdit, setEdit] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(true);
  const [activeMonth, setActiveMonth] = useState([]);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [isModalVisibleImage, setIsModalVisibleImage] = useState(false);
  const [openRecordRetainer, setOpenRecordRetainer] = useState(false);
  const [isModalVisibleDelete, setisModalVisibleDelete] = useState(false);
  const [addRecognition, setAddRecognition] = useState(false);
  const [maxClaimable, setMaxClaimable] = useState("0");
  const [isMonthSelected, setMonthSelected] = useState(false);
  const [monthId, setMonthId] = useState();
  const mastercurrencies = useRef([]);
  const [editEarnings, setEditEarnings] = useState();
  const [deleteEarnings, setDeleteEarnings] = useState();
  const [month, setMonth] = useState<string>("Select Month");
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [currencyList, setCurrencyList] = useState();
  // const [currencyListSymbol, setCurrencyListSymbol] = useState();
  const currencyRate = useSelector((store: any) => store?.currencyRate?.data?.currencyRate);

  const max = Number(maxClaimable.replace(/,/g, ''));
  const currencySymb = useSelector(
    (store: any) => store?.baseCurrency?.baseCurrency
  );
  const EarnedAmt = useRef(0);
  const droppedAmt = useRef(0);
  const gainedAmt = useRef(0);
  const ValueAmt = useRef(0);
  const monthIndex: any = 0;

  const showEditModal = (newItems: any) => {
    setEditAttachmentId(newItems.po_earn_attachments);
    setIsModalVisibleEdit(true);
  };
  const handleCancelModalEdit = () => {
    setIsModalVisibleEdit(false);
  };
  const handleAddRecognition = () => {
    if(isEditable){
    setFiles([]);

    setAddRecognition(true);
    setMonth("Select Month");
    }else{
      notification.open({
        message: "",
        description: "To edit/add values, please switch to base currency editable mode",
        style: { color: "red" },
      });
    }
  };
  const showViewModalImage = (po_earn_attachments: any) => {
    setAttachmentId(
      po_earn_attachments.map((row: any) => {
        return row.attachment_id;
      })
    );
    setIsModalVisibleImage(true);
  };
  const handleCancelModalImage = () => {
    setIsModalVisibleImage(false);
  };
  const showModalDelete = () => {
    setisModalVisibleDelete(true);
  };
  const handleCancelDeleteModal = () => {
    setisModalVisibleDelete(false);
  };
  const validationSchema = yup.object({
    earned_value: yup
    .number()
    .required("Please add Earned value")
    .transform((value) =>
      isNaN(value) || value === null || value === undefined ? 0 : value
    ),
    dropped_value: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' || isNaN(value) ? undefined : value
      )
      .nullable(),
    
    gained_value: yup
      .number()
      .transform((value, originalValue) =>
        originalValue === '' || isNaN(value) ? undefined : value
      )
      .nullable(),
    
    notes: yup
      .string()
      .trim()
      .required("Please add Notes"),
  });

  var token = getToken();
  var FormData = require("form-data");
  var data = new FormData();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState();

  const [previewTitle, setPreviewTitle] = useState();
  const [files, setFiles] = useState<any>([]);

  const handleCancelModal = () => {
    setPreviewVisible(false);
  };

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleAttachmentChange = ({ fileList }: any) => {
    setFiles(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>{recordRetainer.labelForUpload}</div>
    </div>
  );
  const checkNull = (number: any) => {
    if (number === null || number === undefined) {
      return "0";
    } else {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+\b)/g, ",");
    }
  };

  const checkCurrency = (number: any, currencySymbol: string | null) => {
    if (number === null) {
      return "0.00";
    } else {
      if (currencySymbol === "₹") {
        return new Intl.NumberFormat("en-IN", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(number);
      } else {
        return new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }).format(number);
      }
    }
  };
  const handleMonthSelected = () => {
    AxiosConfig.get("rates/" + monthId, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((data) => {
        mastercurrencies.current = data.data?.monthRates?.mastercurrencies;
        const temp: any = data.data?.monthRates?.mastercurrencies.filter(
          (currency: any) =>
            currency.currencyrates.currency_id === props.selectedCurrencyId
        );
        setSelectedCurrencyRate(
          temp[0]?.currencyrates.rate ?? props.currency_rate
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // End Preview Image while uploading

  useEffect(() => {
    getEarnings();
    setOpenRecordRetainer(false);
  }, [props.close, openRecordRetainer]);

  useEffect(() => {
    if (monthId) {
      handleMonthSelected();
    }
  }, [monthId]);

  let currentArray: any = [];
  let monthArray: any = [];

  const getEarnings = () => {
    AxiosConfig.get("poearnings/" + props.poId, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        const data = res.data.data.po_earn;
        data.sort(function (a, b) {
          return a.financialmonth_id - b.financialmonth_id;
        });

        setEarnings(data);
        currentArray = res?.data?.data?.po_earn.filter((row) => {
          return row?.financialmonth?.status === "1";
        });
        monthArray = res.data.data.po_earn.filter((row) => {
          return row?.financialmonth?.status === "-1";
        });
        monthArray = [...currentArray, ...monthArray];

        const attachments = data.map((row) => row.po_earn_attachments).flat();
        attachments.forEach((row) => {
          getAttachments(row.attachment_id);
        });

        setMaxClaimable(
          checkCurrency(
            res.data.data.claimable_value,
            props.currencySymbol[props.selectedCurrencyId]
          )
        );
        setActiveMonth(currentArray);
        setIsLoading(false);
      })
      .catch(() => {
        setEarnings([]);

        setMaxClaimable(
          checkCurrency(
            props.valueSold,
            props.currencySymbol[props.selectedCurrencyId]
          )
        );

        setIsLoading(false);
      });
  };

  useEffect(() => {
    getCurrencyINR();
    // getCurrencySymbol();
  }, []);
  const getCurrencyINR = async () => {
    const currencyList: any = <span className="select-before">INR</span>;
    setCurrencyList(currencyList);
  };
  // const getCurrencySymbol = async () => {
  //   const currencySymbol: any = <span className="select-before">₹</span>;
  //   // setCurrencyListSymbol(currencySymbol);
  // };

  const deleteData = (type:any) => {
    if(deleteEarnings == null || deleteEarnings == undefined){
      const notify = () => {
        notification.open({
          message: "Delete record id missing",
        });
      };
      {
        notify();
      }
    }
    AxiosConfig.delete("/poearnings/" + deleteEarnings, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        if (res.data.status.status === 200) {
          const notify = () => {
            notification.open({
              message: res.data.status.message,
            });
          };
          {
            notify();
          }
          handleCancelDeleteModal();
          if(type=="list"){
            setEdit(false);
            setOpenRecordRetainer(true);
            getEarnings();
            handleCancel();
          }
          getEarnings();
        }
      })
      .catch(function (error) {
        const notify = () => {
          notification.open({
            message: "Hello",
            description: error.res.data.status.message,
            style: { color: "red" },
          });
        };
        {
          notify();
        }
      });
  };
  const [image, setImage] = useState<any>([]);
  const image_arr: any = [];
  const [editImage, setEditImage] = useState<any>([]);
  const edit_image_arr: any = [];

  const getAttachments = (attachment_id: any) => {
    AxiosConfig.get("poearnings/attachments/" + attachment_id, {
      headers: { Authorization: `Bearer ${token}` },
      responseType: "blob",
    }).then((res) => {
      let image_obj: any = {};
      const url: any = URL.createObjectURL(res.data);
      fileData["current"][url] = { data: res.data, attachment_id };
      image_obj["attachment_id"] = attachment_id;
      image_obj["url"] = url;
      image_arr.push(image_obj);
      edit_image_arr.push(image_obj);
      setEditImage(edit_image_arr);
      setImage(image_arr);
    });
  };
  const currentNotes = activeMonth.map((row: { notes: any }) => {
    return row?.notes;
  });
  const newCurrentNotes = currentNotes.join();
  const isEditableString = localStorage.getItem("isEditable");
  const isEditable = isEditableString === 'true'; 
  const currentDirectExpense = activeMonth.map(
    (row: { projected_expenses: number }) => {
      return row?.projected_expenses;
    }
  );
  const newCurrentDirectExpense = currentDirectExpense.join();

  const currentEarnedValue = activeMonth.map((row: { earned_value: any }) => {
    return row?.earned_value;
  });
  const newCurrentEarned = currentEarnedValue.join();

  const currentGainedValue = activeMonth.map((row: { gained_value: any }) => {
    return row?.gained_value;
  });
  const newCurrentGain = currentGainedValue.join();

  const currentDroppedValue = activeMonth.map((row: { dropped_value: any }) => {
    return row?.dropped_value;
  });
  const newCurrentDrop = currentDroppedValue.join();

  const initialFormState = {
    id:"",
    earned_value: "",
    dropped_value: "",
    gained_value: "",
    notes: "",
    earned_value_in_base: "",
    dropped_value_in_base: "",
    gained_value_in_base: "",
    po_currency_conversion_rate: "",
    projected_expenses: "",
    direct_expense: 0,
    po_earn_attachments: "",
  };
  const [newitems, setNewItems] = useState(initialFormState);
  interface Currency {
    symbol: string;
    rate: number;
  }

  const [currencySymbols, setCurrencySymbols] =useState<Currency[]>([]);


const getCurrencyList = async () => {
  if (token) {
    await AxiosConfig.get(`/rates/country/currencyList`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      const currenciesList = res.data.currenciesList || [];
      const currencies = currenciesList.map(currency => ({
        symbol: currency.currency_symbol,
        rate: currency.rate
      }));
      setCurrencySymbols(currencies);
    }).catch((error) => {
      
      notification.error({ message: error,
      description:
        "Unable to fetch currency list. Please try again later.",
      style: { color: "red" },}
        );
     
    });
  }
};

useEffect(() => {
  getCurrencyList();
}, []); 
const convertToINR = (amount: number | string, rate: number): string => {
  
  const amountStr = amount.toString();
 
  const cleanedAmount = parseFloat(amountStr.replace(/,/g, ''));
  if (isNaN(cleanedAmount)) {
    
    return 'NaN';
  }

  const inrAmount = cleanedAmount * rate;
  return inrAmount.toFixed(2); 
};
function checkAndConvertCurrency(value, currSymb) {
  const localStorageCurrencySymb = localStorage.getItem('cur_symbol');

  if (currSymb === localStorageCurrencySymb) {
   
    return `${currSymb} ${value}`;
  } else {

    
    const currency = currencySymbols.find(c => c.symbol === currSymb);
      if (currency) {
        const convertedValue = convertToINR(value, currency.rate);
    
        const newconvertedValue = Number(convertedValue)
        
        return convertCurrency(newconvertedValue, currencyRate);
        
      }
  }
}


  useEffect(() => {
    let newFormState;
    if (editEarnings) {
      newFormState = {
        id:filtered[0].key,
        earned_value: filtered[0].Earned_Value,
        Earned_Value_In_Base: filtered[0].Earned_Value_In_Base,
        dropped_value: filtered[0].Dropped_Value,
        dropped_value_in_base: filtered[0].Dropped_Value_In_Base,
        gained_value: filtered[0].Gained_Value,
        gained_value_in_base: filtered[0].Gained_Value_In_Base,
        notes: filtered[0].Notes,
        po_currency_conversion_rate: filtered[0].Po_Currency_Conversion_Rate,
        projected_expenses: filtered[0].projected_expenses,
        direct_expense: filtered[0].expense,
        po_earn_attachments: filtered[0].attachments.map((row: any) => {
          return row.attachment_id;
        }),
        // po_id: filtered[0].id,
      };
    } else {
      newFormState = {
        id:"",
        po_currency_conversion_rate: selectedCurrencyRate,
        earned_value: newCurrentEarned,
        dropped_value: newCurrentDrop,
        gained_value: newCurrentGain,
        notes: newCurrentNotes,
        projected_expenses: newCurrentDirectExpense,
      };
    }

    setNewItems(newFormState);
  }, [isDisabledInput]);

  const handleChangeEvent = (event: any) => {
    setNewItems({
      ...newitems,
      [event.target.name]: event.target.value,
    });
  };

  const EarningData = earnings.map(
    (row: {
      id: any;
      dropped_value: any;
      dropped_value_in_base: any;
      earned_value: any;
      earned_value_in_base: any;
      gained_value: any;
      gained_value_in_base: any;
      financialmonth: any;
      notes: any;
      po_earn_attachments: any;
      po_currency_conversion_rate: any;
      projected_expenses: number;
      expense: any;
    }) => ({
      Dropped_Value: checkNull(row?.dropped_value),
      Dropped_Value_In_Base: checkNull(row?.dropped_value_in_base),
      Po_Currency_Conversion_Rate: row?.po_currency_conversion_rate,
      Earned_Value: checkNull(row?.earned_value),
      Earned_Value_In_Base: checkNull(row?.earned_value_in_base),
      Gained_Value: checkNull(row?.gained_value),
      Gained_Value_In_Base: checkNull(row?.gained_value_in_base),
      Month: row?.financialmonth.month,
      Notes: row?.notes,
      attachments: row?.po_earn_attachments,
      key: row?.id,
      projected_expenses: row?.projected_expenses,
      expense: row?.expense,
    })
  );

  const filtered = EarningData.filter((month) => editEarnings === month.key);

  const monthMenu = (
    <Menu title="Select Month" className="monthsMenu">
      {props.monthListFiltered.map(
        (month) =>
          !monthsArray.current.includes(month.id) && (
            <Menu.Item
              className="monthsMenu"
              key={month.id}
              onClick={() => {
                setMonthId(month.id);
                setMonthSelected(true);
                setMonth(`${month.month} ${month.year}`);
              }}
            >
              {`${month.month} ${month.year}`}
            </Menu.Item>
          )
      )}
    </Menu>
  );

  const handleCancel = () => {
    setIsDisabledInput(true);
    setAddRecognition(false);
    setMonthSelected(false);
    refreshPage();
  };
  const refreshPage = () => {
    props.close;
  };
  const handleDownload = (file_id: any) => {
    saveAs(
      fileData["current"][file_id]["data"],
      fileData["current"][file_id]["attachment_id"].split("^").pop()
    );
  };

  const getEditImages = () => {
    const editImages = editImage
      .filter((img) =>
        (attachments_id || []).some(
          (attachmentId: string) => attachmentId === img["attachment_id"]
        )
      )
      .map((img_url: any, index: any) => {
        const fileNameWithExtension =
          fileData["current"][img_url.url]?.["attachment_id"];

        let fileName, fileType;

        if (fileNameWithExtension.includes("_")) {
          fileName = fileNameWithExtension.split("_").slice(0, -1).join("_");
        } else {
          fileName = fileNameWithExtension.split("^")[1];
        }

        fileType = fileNameWithExtension.split(".").pop();

        {
          return (
            <div className="attachment" key={index}>
              {(() => {
                if (fileType.toLowerCase() === "pdf") {
                  return (
                    <div className="pdf-placeholder">
                      <img
                        src={pdfPlaceholderImage}
                        alt="PDF Placeholder"
                        className="pdf-placeholder-image"
                      />
                    </div>
                  );
                } else if (fileType.toLowerCase() === "xlsx") {
                  return (
                    <div className="xlsx-placeholder">
                      <img
                        src={xlsxPlaceholderImage}
                        alt="XLSX Placeholder"
                        className="xlsx-placeholder-image"
                      />
                    </div>
                  );
                } else if (fileType.toLowerCase() === "docx") {
                  return (
                    <div className="docx-placeholder">
                      <img
                        src={docxPlaceholderImage}
                        alt="DOCX Placeholder"
                        className="docx-placeholder-image"
                      />
                    </div>
                  );
                } else {
                  return (
                    <Image
                      className="attachment-image"
                      width={62}
                      height={62}
                      src={img_url.url}
                      alt="Attachment"
                    />
                  );
                }
              })()}
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => handleDownload(img_url.url)}
              />
              <text>{fileName}</text>
            </div>
          );
        }
      });
    return editImages;
  };

  const getImages = () => {
    const images = image
      .filter((img) =>
        (attachments_id || []).some(
          (attachmentId: string) => attachmentId === img["attachment_id"]
        )
      )
      .map((img_url: any, index: any) => {
        const fileNameWithExtension =
          fileData["current"][img_url.url]?.["attachment_id"];

        let fileName, fileType;

        if (fileNameWithExtension.includes("_")) {
          fileName = fileNameWithExtension.split("_").slice(0, -1).join("_");
        } else {
          fileName = fileNameWithExtension.split("^")[1];
        }

        fileType = fileNameWithExtension.split(".").pop();

        {
          return (
            <div className="attachment" key={index}>
              {(() => {
                if (fileType.toLowerCase() === "pdf") {
                  return (
                    <div className="pdf-placeholder">
                      <img
                        src={pdfPlaceholderImage}
                        alt="PDF Placeholder"
                        className="pdf-placeholder-image"
                      />
                    </div>
                  );
                } else if (fileType.toLowerCase() === "xlsx") {
                  return (
                    <div className="xlsx-placeholder">
                      <img
                        src={xlsxPlaceholderImage}
                        alt="XLSX Placeholder"
                        className="xlsx-placeholder-image"
                      />
                    </div>
                  );
                } else if (fileType.toLowerCase() === "docx") {
                  return (
                    <div className="docx-placeholder">
                      <img
                        src={docxPlaceholderImage}
                        alt="DOCX Placeholder"
                        className="docx-placeholder-image"
                      />
                    </div>
                  );
                } else {
                  return (
                    <Image
                      className="attachment-image"
                      width={62}
                      height={62}
                      src={img_url.url}
                      alt="Attachment"
                    />
                  );
                }
              })()}
              <Button
                type="primary"
                shape="round"
                icon={<DownloadOutlined />}
                size="small"
                onClick={() => handleDownload(img_url.url)}
              />
              <text>{fileName}</text>
            </div>
          );
        }
      });
    return images;
  };

  const RecognitionForm = (
    <Formik
      initialValues={{
        financialmonth_id: props.currentMonthId,
        earned_value: "",
        earned_value_in_base: "",
        dropped_value: "",
        dropped_value_in_base: "",
        gained_value: "",
        gained_value_in_base: "",
        po_currency_conversion_rate: "",
        po_id: props.poId,
        notes: "",
        files: [],
        projected_expenses: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const totalAmount = earnings.reduce(
          (total, item: any) =>
            total + (parseFloat(item?.projected_expenses) || 0),
          0
        );
        const directExpenseValue = parseFloat(values?.projected_expenses);
        const propsDirectExpense = parseFloat(
          props?.directExpense?.toString() ?? "0"
        );
        const totalGainedValue = earnings.reduce(
          (total, item: any) =>
          total + (parseFloat(item?.gained_value) || 0),
          0
        ) || 0;
        const totalDroppedValue = earnings.reduce(
          (total, item: any) =>
          total + (parseFloat(item?.dropped_value) || 0),
          0
        )|| 0;
        const dropped_value = (parseFloat(values.dropped_value)+totalDroppedValue) || 0;
        const gained_value = (parseFloat(values.gained_value)+totalGainedValue) || 0; 
         if ((parseInt(values.earned_value) > (max+gained_value)) ||
            (parseInt(values.earned_value+dropped_value) > (max+gained_value))) {
          notification.open({
            message: "",
            description:
              "Earned value exceeds the max claimable for the PO",
            style: { color: "red" },
          });
          return;
        }
        if (totalAmount + directExpenseValue > propsDirectExpense) {
          notification.open({
            message: "",
            description:
              "Projected Direct STUs/non-STUs expenses exceeds the total Projected Direct STUs/non-STUs expenses for the PO",
            style: { color: "red" },
          });
          return;
        }

        for (let i = 0; i < files.length; i++) {
          data.append("files", files[i].originFileObj);
        }
        data.append("financialmonth_id", monthId);
        data.append("po_id", values.po_id);
        data.append("gained_value", values.gained_value);
        data.append("earned_value", values.earned_value);
        data.append("dropped_value", values.dropped_value);
        data.append("notes", values.notes);
        data.append(
          "po_currency_conversion_rate",
          Number(selectedCurrencyRate)
        );
        data.append(
          "earned_value_in_base",
          Number(values.earned_value) * Number(selectedCurrencyRate)
        );
        data.append(
          "dropped_value_in_base",
          Number(values.dropped_value) * Number(selectedCurrencyRate)
        );
        data.append(
          "gained_value_in_base",
          Number(values.gained_value) * Number(selectedCurrencyRate)
        );
        data.append(
          "projected_expenses",
          values?.projected_expenses ? values?.projected_expenses : 0
        );

        AxiosConfig.post("poearnings", data, {
          headers: { Authorization: `Bearer ${token}` },
        })
          .then((response) => {
            if (response.status === 200) {
              const notify = () => {
                notification.open({
                  message: response.data.status.message,
                });
              };
              {
                notify();
              }
              setOpenRecordRetainer(true);
              getEarnings();
              handleCancel();
            }
          })
          .catch(function (error) {
            const notify = () => {
              notification.open({
                message: "",
                description: error.response.data.status.message,
                style: { color: "red" },
              });
            };
            {
              notify();
              for (const key in values) {
                data.delete(key);
              }
            }
          });
      }}
    >
      {({ handleSubmit, handleChange, errors, values }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          <>
            {addRecognition && (
              <div className="listing-bg main-div">
                <Row>
                  <Col span={6}>
                    <Divider
                      type="horizontal"
                      orientation="right"
                      className="divider"
                    >
                      <Dropdown
                        overlay={monthMenu}
                        trigger={["click"]}
                        placement="bottomLeft"
                      >
                        <Button>
                          {month || "Select Month"}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                    </Divider>
                  </Col>
                  <Col span={18}>
                    <Divider orientation="right"></Divider>
                  </Col>
                </Row>
                {isMonthSelected && (
                  <>
                    <Row gutter={16}>
                      <Col span={8}>
                        <label>{recordRetainer.labelForEarned} </label>
                        <Input
                          placeholder="Enter the Earned"
                          name="earned_value"
                          onChange={(event: any) => {
                            EarnedAmt.current = event?.target?.value;
                            return handleChange(event);
                          }}
                          type="number"
                          value={values.earned_value}
                          className="input--field"
                          addonBefore={
                            props.currencySymbol[props.selectedCurrencyId]
                          }
                        ></Input>
                        <p className="conversion-text">
                          {recordRetainer.labelForConversionRate}
                          {currencySymb?.currency_symbol}
                          {selectedCurrencyRate} | {currencySymb?.currency_code}
                          :
                          {formatCurrencyValueDecimal(
                            currencySymb?.currency_code,
                            Number(selectedCurrencyRate) * EarnedAmt?.current
                          )}
                        </p>
                        <p className="display_error">{errors.earned_value}</p>
                      </Col>
                      <Col span={8}>
                        <label>{recordRetainer.labelForDropped} </label>
                        <Input
                          placeholder="Enter the Dropped"
                          name="dropped_value"
                          onChange={(event: any) => {
                            droppedAmt.current = event?.target?.value;
                            return handleChange(event);
                          }}
                          type="number"
                          value={values.dropped_value}
                          className="input--field"
                          addonBefore={
                            props.currencySymbol[props.selectedCurrencyId]
                          }
                        ></Input>
                        <p className="conversion-text">
                          {recordRetainer.labelForConversionRate}
                          {currencySymb?.currency_symbol}
                          {selectedCurrencyRate} | {currencySymb?.currency_code}
                          :
                          {formatCurrencyValueDecimal(
                            currencySymb?.currency_code,
                            Number(selectedCurrencyRate) * droppedAmt?.current
                          )}
                        </p>
                        <p className="display_error">{errors.dropped_value}</p>
                      </Col>

                      <Col span={8}>
                        <label> {recordRetainer.labelForGained} </label>
                        <Input
                          placeholder="Enter the Gained"
                          name="gained_value"
                          onChange={(event: any) => {
                            gainedAmt.current = event?.target?.value;
                            return handleChange(event);
                          }}
                          type="number"
                          value={values.gained_value}
                          className="input--field"
                          addonBefore={
                            props.currencySymbol[props.selectedCurrencyId]
                          }
                        ></Input>
                        <p className="conversion-text">
                          {recordRetainer.labelForConversionRate}
                          {currencySymb?.currency_symbol}
                          {selectedCurrencyRate} | {currencySymb?.currency_code}
                          :
                          {formatCurrencyValueDecimal(
                            currencySymb?.currency_code,
                            Number(selectedCurrencyRate) * gainedAmt?.current
                          )}
                        </p>
                        <p className="display_error">{errors.gained_value}</p>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col span={8}>
                        <label>
                          {recordRetainer.textForProjectedDirectExpenses}
                        </label>
                        <Input
                          type="number"
                          placeholder="Enter the expenses"
                          name="projected_expenses"
                          onChange={(event: any) => {
                            ValueAmt.current = event.target.value;
                            return handleChange(event);
                          }}
                          value={values.projected_expenses}
                          addonBefore={currencyList}
                          className="direct-expense-input"
                          min={0}
                          onKeyPress={preventMinus}
                        ></Input>
                      </Col>
                      <Col span={10}>
                        <label>{recordRetainer.labelForNotes} </label>
                        <TextArea
                          placeholder="Enter the Notes"
                          name="notes"
                          onChange={handleChange}
                          value={values.notes}
                          className="textarea--field"
                          rows={3}
                        />
                        <p className="display_error">{errors.notes}</p>
                      </Col>
                      <Col span={6}>
                        <label>{recordRetainer.labelForAttachments}</label>
                        <Upload
                          listType="picture-card"
                          fileList={files}
                          onPreview={handlePreview}
                          onChange={handleAttachmentChange}
                          beforeUpload={() => false}
                        >
                          {uploadButton}
                        </Upload>
                        <Modal
                          visible={previewVisible}
                          title={previewTitle}
                          footer={null}
                          onCancel={handleCancelModal}
                        >
                          <img
                            alt="example"
                            style={{ width: "100%" }}
                            src={previewImage}
                          />
                        </Modal>
                      </Col>
                    </Row>

                    <div
                      className="button-container"
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginRight: "0",
                      }}
                    >
                      {props.rolesEditAccess && <ButtonReuse
                        type="primary"
                        className="primary-btn"
                        htmlType="submit"
                        value="Add"
                      ></ButtonReuse>}
                      {props.rolesEditAccess && <ButtonReuse
                        type="primary"
                        className="cancel-btn"
                        htmlType="submit"
                        value="Cancel"
                        onClick={handleCancel}
                      ></ButtonReuse>}
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        </form>
      )}
    </Formik>
  );
  const EditRecognitionForm = (
    <Formik
      initialValues={{
        earned_value: Number(
          EarningData[monthIndex]?.Earned_Value.replace("$", "").replaceAll(
            ",",
            ""
          )
        ),
        dropped_value: Number(
          EarningData[monthIndex]?.Dropped_Value.replace("$", "").replaceAll(
            ",",
            ""
          )
        ),
        gained_value: Number(
          EarningData[monthIndex]?.Gained_Value.replace("$", "").replaceAll(
            ",",
            ""
          )
        ),
        notes: EarningData[monthIndex]?.Notes,
        earned_value_in_base: Number(
          EarningData[monthIndex]?.Earned_Value_In_Base
        ),
        dropped_value_in_base: Number(
          EarningData[monthIndex]?.Dropped_Value_In_Base
        ),
        gained_value_in_base: Number(
          EarningData[monthIndex]?.Gained_Value_In_Base
        ),
        po_currency_conversion_rate: Number(
          EarningData[monthIndex]?.Po_Currency_Conversion_Rate
        ),
        projected_expenses: Number(EarningData[monthIndex]?.projected_expenses),
      }}
      validationSchema={validationSchema}
      onSubmit={() => {
        const newValues = {
          earned_value: Number(newitems.earned_value.replaceAll(",", "")),
          dropped_value: Number(newitems.dropped_value.replaceAll(",", "")),
          gained_value: Number(newitems.gained_value.replaceAll(",", "")),
          notes: newitems.notes,
          po_currency_conversion_rate: Number(
            newitems.po_currency_conversion_rate
          ),
          earned_value_in_base:
            Number(newitems.earned_value.replaceAll(",", "")) *
            Number(newitems.po_currency_conversion_rate),
          dropped_value_in_base:
            Number(newitems.dropped_value.replaceAll(",", "")) *
            Number(newitems.po_currency_conversion_rate),
          gained_value_in_base:
            Number(newitems.gained_value.replaceAll(",", "")) *
            Number(newitems.po_currency_conversion_rate),
          projected_expenses: Number(newitems.projected_expenses),
          direct_expense: Number(newitems.direct_expense),
          // po_id: Number(newitems.po_id)
        };

        const adddata = newValues;
        for (var propName in adddata) {
          if (adddata[propName] === null || adddata[propName] === "") {
            delete adddata[propName];
          }
        }
        if (newitems.notes.trim().length > 0) {
          AxiosConfig.put("poearnings/" + editEarnings, adddata, {
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((response) => {
              setEdit(false);
              if (response.status === 200) {
                const notify = () => {
                  notification.open({
                    message: response.data.status.message,
                  });
                };
                {
                  notify();
                }
                setOpenRecordRetainer(true);
                getEarnings();
                handleCancel();
              }
            })
            .catch(function (error) {
              if (error.response.status === 404) {
                notification.open({
                  message: "",
                  description:
                    "Projected Direct STUs/non-STUs expenses exceeds the total Projected Direct STUs/non-STUs expenses for the PO",
                  style: { color: "red" },
                });
              } else {
                const notify = () => {
                  notification.open({
                    message: "",
                    description: error.response.data.status.message,
                    style: { color: "red" },
                  });
                };
                notify();
              }
            });
        }
      }}
    >
      {({ handleSubmit, errors, resetForm }) => (
        <form onSubmit={handleSubmit} className="add_po_earning_form">
          {!isDisabledInput && (
            <>
              <div className="listing-bg main-div">
                <Row>
                  <Col span={8}>
                    <label>{recordRetainer.labelForEarned} </label>
                    <Input
                      placeholder="Enter the Earned"
                      name="earned_value"
                      type="number"
                      onChange={handleChangeEvent}
                      value={newitems.earned_value.replaceAll(",", "")}
                      className="input--field"
                      addonBefore={
                        props.currencySymbol[props.selectedCurrencyId]
                      }
                    ></Input>
                     {!newitems.earned_value ? (
                      <p className="display_error">Please enter earend value</p>
                    ) : null}
                    <p className="conversion-text">
                      {recordRetainer.labelForConversionRate}
                      {currencySymb?.currency_symbol}
                      {newitems.po_currency_conversion_rate} |
                      {currencySymb?.currency_code}:
                      {currencySymb?.currency_symbo}
                      {Number(newitems.po_currency_conversion_rate) *
                        Number(newitems.earned_value.replaceAll(",", ""))}
                    </p>
                    <p className="display_error">{errors.earned_value}</p>
                  </Col>
                  <Col span={8}>
                    <label>{recordRetainer.labelForDropped} </label>
                    <Input
                      placeholder="Enter the Dropped"
                      name="dropped_value"
                      onChange={handleChangeEvent}
                      type="number"
                      value={newitems.dropped_value.replaceAll(",", "")}
                      className="input--field"
                      addonBefore={
                        props.currencySymbol[props.selectedCurrencyId]
                      }
                    ></Input>
                    <p className="conversion-text">
                      {recordRetainer.labelForConversionRate}
                      {currencySymb?.currency_symbol}
                      {newitems.po_currency_conversion_rate} |
                      {currencySymb?.currency_code}:{" "}
                      {currencySymb?.currency_symbol}
                      {Number(newitems.po_currency_conversion_rate) *
                        Number(newitems.dropped_value.replaceAll(",", ""))}
                    </p>
                    <p className="display_error">{errors.dropped_value}</p>
                  </Col>
                  <Col span={8}>
                    <label>{recordRetainer.labelForGained} </label>
                    <Input
                      placeholder="Enter the Gained"
                      name="gained_value"
                      onChange={handleChangeEvent}
                      type="number"
                      value={newitems.gained_value.replaceAll(",", "")}
                      className="input--field"
                      addonBefore={
                        props.currencySymbol[props.selectedCurrencyId]
                      }
                    ></Input>
                    <p className="conversion-text">
                      {recordRetainer.labelForConversionRate}
                      {currencySymb?.currency_symbol}
                      {newitems.po_currency_conversion_rate} |
                      {currencySymb?.currency_code}:
                      {currencySymb?.currency_symbol}
                      {Number(newitems.po_currency_conversion_rate) *
                        Number(newitems.gained_value.replaceAll(",", ""))}
                    </p>
                    <p className="display_error">{errors.gained_value}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}>
                    <label>
                      {recordRetainer.textForProjectedDirectExpenses}
                    </label>
                    <Input
                      placeholder="Enter the expenses"
                      name="projected_expenses"
                      onChange={handleChangeEvent}
                      value={newitems.projected_expenses}
                      addonBefore={<span className="select-before">INR</span>}
                      className="direct-expense-input"
                      min={0}
                      onKeyPress={preventMinus}
                    ></Input>
                  </Col>
                  <Col span={8}>
                    <label>{"Actual Direct Non/STU Expenses"}</label>
                    <Input
                      type="number"
                      placeholder="Enter the expenses"
                      name="direct_expense"
                      // onChange={handleChangeEvent}
                      value={newitems.direct_expense}
                      addonBefore={<span className="select-before">INR</span>}
                      className="direct-expense-input"
                      disabled={true}
                    ></Input>
                  </Col>
                  <Col span={10}>
                    <label>{recordRetainer.labelForNotes} </label>
                    <TextArea
                      placeholder="Enter the Notes"
                      name="notes"
                      onChange={handleChangeEvent}
                      value={newitems?.notes}
                      className="textarea--field"
                      rows={3}
                    />
                    {!newitems.notes || !newitems.notes.trim().length ? (
                      <p className="display_error">Please add Notes</p>
                    ) : null}
                  </Col>
                  <Col span={6}>
                    <label>{recordRetainer.labelForAttachments}</label>
                    {props.rolesEditAccess &&<ButtonReuse
                      value="Edit"
                      type="submit"
                      className="edit-image-button"
                      onClick={() => showEditModal(newitems)}
                    />}
                    {isModalVisibleEdit && (
                      <Modal
                        title="Attachments"
                        className="AttachmentsModal"
                        visible={isModalVisibleEdit}
                        onCancel={handleCancelModalEdit}
                      >
                        <UploadAttachment earningsId={editEarnings} />
                        <EditPoAttachmentsRecordRetainer
                          images={getEditImages()}
                          poId={props.poId}
                          attachmentsId={editAttachment_id}
                        />
                      </Modal>
                    )}
                  </Col>
                </Row>

                <div
                  className="po-button-container"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "0",
                  }}
                >
                  {props.rolesEditAccess && <ButtonReuse
                    type="primary"
                    className="update-po-button"
                    htmlType="submit"
                    value="Save"
                  ></ButtonReuse>}
                  {props.rolesEditAccess && <ButtonReuse
                    value="Cancel"
                    type="primary"
                    className="cancel-button-small"
                    onClick={() => {
                      setEdit(false);
                      handleCancel();
                      resetForm();
                    }}
                  />}
                  {props.rolesEditAccess && <ButtonReuse
                    value="Delete"
                    type="primary"
                    className="delete-button"
                    onClick={() => {                     
                      setDeleteEarnings(editEarnings);
                      showModalDelete();
                    }}
                  />}
                  <Modal
                    title="Delete Current Month Revenue"
                    visible={isModalVisibleDelete}
                    onCancel={handleCancelDeleteModal}
                    cancelText="Cancel"
                    footer={false}
                    className="deleteModalMain"
                  >
                    <p>{recordRetainer.textForDeleteModal}</p>
                    <Row>
                      <Col span={12}>
                        <ButtonReuse
                          value="Delete"
                          type="submit"
                          className="modal-deleteBtn"
                          onClick={() => {
                            deleteData("list");
                          }}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonReuse
                          value="Cancel"
                          type="submit"
                          className="modal-deleteCancelBtn"
                          onClick={handleCancelDeleteModal}
                        />
                      </Col>
                    </Row>
                  </Modal>
                </div>
              </div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
  localStorage.setItem("isEarning", JSON.stringify(activeMonth.length));
  return (
    <>
      <Row>
        <Col span={8}>
          <Row>
            <label>{recordRetainer.labelForCurrentFinancialMonth} </label>
          </Row>
          <Row>
            <strong>
              {props.currentMonth} {props.activeYear}
            </strong>
          </Row>
        </Col>
        <Col span={8}>
          <Row>
            <label> {recordRetainer.labelForMaxClaimable} </label>
          </Row>
          <Row>
            <strong>

              {isEditable ? 
                  `${props.currencySymbol[props.selectedCurrencyId]} ${maxClaimable}` :
                  checkAndConvertCurrency(max, props.currencySymbol[props.selectedCurrencyId])
                }
            </strong>
          </Row>
        </Col>
        <Col span={8}>
          {!isEdit && !props.archiveStauts && props.rolesEditAccess && (
            <ButtonReuse
              type="primary"
              className="primary-btn addReuseButton"
              onClick={handleAddRecognition}
              value="Add Recognition"
            ></ButtonReuse>
          )}
        </Col>
      </Row>
      {earnings.length === 0 && !addRecognition && (
        <img
          src={NoData}
          width="184"
          height="152"
          className="recognitionEmpty-img"
        />
      )}
      {addRecognition && RecognitionForm}
      {!isLoading ? (
        <div>
          {isDisabledInput && (
            <>
              {earnings &&
                earnings.map(
                  (
                    row: {
                      i: any;
                      id: any;
                      financialmonth: any;
                      earned_value: any;
                      dropped_value: any;
                      gained_value: any;
                      earned_value_in_base: any;
                      gained_value_in_base: any;
                      dropped_value_in_base: any;
                      po_currency_conversion_rate: any;
                      notes: any;
                      stus_executed: any;
                      expense: number;
                      projected_expenses: number;
                      po_earn_attachments: any;
                    },
                    index: any
                  ) => {
                    monthsArray.current = [
                      ...monthsArray.current,
                      row?.financialmonth.id,
                    ];
                    const dropped_value_in_base = row?.dropped_value_in_base
                      ? row?.dropped_value_in_base
                      : 0;
                    const earned_value_in_base = row?.earned_value_in_base
                      ? row?.earned_value_in_base
                      : 0;
                    const projected_expenses = row?.projected_expenses
                      ? row?.projected_expenses
                      : 0;

                    return (
                      <div key={index} className="listing-bg">
                        <Row>
                          <Col span={!addRecognition && !isEdit ? 20 : 24}>
                            <Divider
                              type="horizontal"
                              orientation="left"
                              className="divider"
                            >
                              {row?.financialmonth.month}-
                              {row?.financialmonth.year}
                            </Divider>
                          </Col>

                          {!addRecognition && !isEdit && !props.archiveStauts && isEditable &&(
                            <Col span={2}>
                              <Divider type="horizontal" orientation="right">
                                {rolesEditAccess && <Col>
                                  {row?.financialmonth.status >= 0 && props.rolesEditAccess && (
                                    <img
                                      src={EditImage}
                                      width="25"
                                      height="25"
                                      onClick={() => {
                                        setEdit(true);
                                        setIsDisabledInput(false);
                                        setEditEarnings(row?.id);
                                      }}
                                    />
                                  )}
                                </Col>}
                              </Divider>
                            </Col>
                          )}

                          {!addRecognition && !isEdit && !props.archiveStauts && isEditable && (
                            <Col span={2}>
                              <Divider type="horizontal" orientation="right">
                                {rolesEditAccess && <Col>
                                  {row?.financialmonth.status >= 0 && props.rolesEditAccess &&(
                                    <img
                                      src={Delete}
                                      width="25"
                                      height="25"
                                      onClick={() => {
                                        setDeleteEarnings(row?.id);
                                        showModalDelete();
                                      }}
                                    />
                                  )}
                                </Col>}
                                <Modal
                                  title="Delete Current Month Record"
                                  visible={isModalVisibleDelete}
                                  onCancel={handleCancelDeleteModal}
                                  cancelText="Cancel"
                                  footer={false}
                                  className="deleteModalMain"
                                >
                                  <p>{recordRetainer.textForDeleteModal}</p>
                                  <Row>
                                    <Col span={12}>
                                      <ButtonReuse
                                        value="Delete"
                                        type="submit"
                                        className="modal-deleteBtn"
                                        onClick={() => {
                                          deleteData("edit");
                                        }}
                                      />
                                    </Col>
                                    <Col span={12}>
                                      <ButtonReuse
                                        value="Cancel"
                                        type="submit"
                                        className="modal-deleteCancelBtn"
                                        onClick={handleCancelDeleteModal}
                                      />
                                    </Col>
                                  </Row>
                                </Modal>
                              </Divider>
                            </Col>
                          )}
                        </Row>
                        <Row gutter={16}>
                          <Col span={8}>
                            <label>{recordRetainer.labelForEarned} </label>
                            <p
                              className="newCurrentEarn"
                              style={{ display: "grid" }}
                            >
                             
                             <Text >
                             {isEditable ? (
                              <>
                                {(() => {
                                  const primaryText = `${props.currencySymbol[props.selectedCurrencyId]} ${Intl.NumberFormat("en-IN").format(row?.earned_value)}`;
                                  const formattedEarnedValueInBase = Number(earned_value_in_base).toFixed(2);
                                  const secondaryText = `${currencySymb?.currency_symbol} ${Intl.NumberFormat("en-IN").format(Number(formattedEarnedValueInBase))}`;

                                  const showSecondaryText = primaryText !== secondaryText;

                                  return (
                                    <>
                                      <span>{primaryText}</span>
                                      {showSecondaryText && (
                                        <>
                                          <br />
                                          <Text type="secondary">
                                            {secondaryText}
                                          </Text>
                                        </>
                                      )}
                                    </>
                                  );
                                })()}
                              </>
                            ) : (
                                convertCurrency(earned_value_in_base, currencyRate)
                              )}
                              
                            </Text>
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRetainer.labelForDropped} </label>

                            <p
                              className="newCurrentEarn"
                              style={{ display: "grid" }}
                            >

                            {isEditable ? (
                              (() => {
                                const currencyAmount = `${props.currencySymbol[props.selectedCurrencyId]} ${convertNumberToAmounts(row?.dropped_value, 2)}`;
                                let formattedCurrencyAmount = currencyAmount.trim(); // Remove any surrounding spaces

                                if (currencyAmount.startsWith("₹")) {
                                  const numericValue = parseFloat(row?.dropped_value);
                                  if (!isNaN(numericValue)) {
                                    formattedCurrencyAmount = `₹ ${Intl.NumberFormat("en-IN").format(
                                      Number(numericValue.toFixed(2))
                                    )}`;
                                  }
                                }

                                const secondaryCurrencyAmount = `${currencySymb?.currency_symbol} ${Intl.NumberFormat("en-IN").format(
                                  Number(convertNumberToAmounts(dropped_value_in_base, 2))
                                )}`.trim(); 

                                if (formattedCurrencyAmount === secondaryCurrencyAmount) {
                                  return <span>{formattedCurrencyAmount}</span>;
                                }


                                return (
                                  <>
                                    <span>{formattedCurrencyAmount}</span>
                                    <Text type="secondary">{secondaryCurrencyAmount}</Text>
                                  </>
                                );
                              })()
                            ) : (
                              convertCurrency(dropped_value_in_base, currencyRate)
                            )}

   
                            
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRetainer.labelForGained} </label>

                            <p
                              className="newCurrentEarn"
                              style={{ display: "grid" }}
                            >

                              {isEditable?(
                                (() => {
                                  const currencySymbol = props.currencySymbol[props.selectedCurrencyId];
                                  const gainedValue = checkNull(row?.gained_value);

                                  const currencyAmount = `${currencySymbol} ${gainedValue}`;

                                  let formattedCurrencyAmount = currencyAmount;

                                  if (currencyAmount.startsWith("₹")) {
                                    const numericValue = parseFloat(row?.gained_value);
                                    if (!isNaN(numericValue)) {
                                      formattedCurrencyAmount = `₹ ${Intl.NumberFormat("en-IN").format(numericValue)}`;
                                    }
                                  }

                                  const secondaryCurrencySymbol = currencySymb?.currency_symbol;
                                  const gainedValueInBase = convertNumberToAmounts(row?.gained_value_in_base, 2);

                                  const secondaryCurrencyAmount = `${secondaryCurrencySymbol} ${Intl.NumberFormat("en-IN").format(Number(gainedValueInBase))}`;

                                  if (formattedCurrencyAmount === secondaryCurrencyAmount) {
                                    return <span>{formattedCurrencyAmount}</span>;
                                  }
                                  
                                  

                                  return (
                                    <>
                                      <span>{formattedCurrencyAmount}</span>
                                      <Text type="secondary">{secondaryCurrencyAmount}</Text>
                                    </>
                                  );
                                })()
                              ) : (
                                convertCurrency(row?.gained_value_in_base, currencyRate)
                              )}


                              <Text>
                                
                              </Text>
                            </p>
                          </Col>
                        </Row>
                        <Row gutter={16}>
                          <Col span={8}>
                            <label>{recordRetainer.labelForStusEarned} </label>

                            <p className="newCurrentEarn">
                              {row?.stus_executed?.toFixed(2)}
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>
                              {recordRetainer.textForProjectedDirectExpenses}
                            </label>
                            <br />
                            <p className="newCurrentNotes">
                              {/* {currencyListSymbol}
                              {projected_expenses} */}
                              {
                                convertCurrency(
                                  projected_expenses,
                                  currencyRate
                                )
                              }
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>
                              {recordRetainer.textForDirectExpenses}
                            </label>
                            <br />
                            <p className="newCurrentNotes">
                              {/* <span className="select-before">₹</span> */}
                              {convertCurrency(row?.expense, currencyRate)}
                              {/* {row?.expense?.toLocaleString("en-IN")} */}
                            </p>
                          </Col>
                          <Col span={8}>
                            <label>{recordRetainer.labelForNotes} </label>
                            <br />
                            <p className="newCurrentNotes">{row?.notes}</p>
                          </Col>
                          <Col span={6}>
                            <label>{recordRetainer.labelForAttachments}</label>
                            {props.rolesEditAccess && <ButtonReuse
                              value="View"
                              type="submit"
                              className="edit-image-button"
                              onClick={() =>
                                showViewModalImage(row.po_earn_attachments)
                              }
                            />}
                          </Col>
                        </Row>
                      </div>
                    );
                  }
                )}
            </>
          )}
          {!isDisabledInput && EditRecognitionForm}
        </div>
      ) : (
        <Loader />
      )}
      {isModalVisibleImage && (
        <Modal
          title="Attachments"
          className="AttachmentsModal"
          visible={isModalVisibleImage}
          onCancel={handleCancelModalImage}
        >
          <ViewPoAttachment images={getImages()} />
        </Modal>
      )}
    </>
  );
};

export default RecordRetainer;