import { Table, Input, Row, Col, Tooltip } from "antd";
import { useState, useEffect, useRef } from "react";
import { AxiosConfig } from "../../../../ApiConfig";
import { getToken } from "../../../../auth";
import { CSVLink } from "react-csv";
import { ExportOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import moment from "moment";
import { checkTalentStatus } from "../../../../utils/util";
import Loader from "../../../../components/Loader/Loader";
import { Buttons } from "../../../../utils/enums";

require("./TalentAllocation.scss");

const TalentAllocation = () => {
  const [projectMonth, setProjectMonth] = useState({
    ThisMonth: String,
    NextMonth: String,
    FutureMonth: String,
    ThisMonthYear: String,
    NextMonthYear: String,
    FutureMonthYear: String,
  });
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [nameSearch, setNameSearch] = useState("");
  const [bands, setBands] = useState([]);
  const [department, setDepartments] = useState([]);
  const [skills, setSkills] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [csvData, setCsvData] = useState([]);
  const [filterTotalCount, setFilterTotalCount] = useState(0);

  const [currentMonth, setCurrentMonth] = useState("");
  const currentTableData = useRef<any>([]);

  const token = getToken();

  useEffect(() => {
    if (currentMonth) getData(currentMonth);
  }, [currentMonth]);

  useEffect(() => {
    getBands();
  }, []);

  useEffect(() => {
    getDepatments();
  }, []);

  useEffect(() => {
    getSkillSet();
  }, []);
  useEffect(() => {
    getMonthsId();
  }, []);

  useEffect(() => {
    if (data) {
      const normalizedSearch = nameSearch
        .trim()
        .toLowerCase()
        .replace(/\s+/g, ' ');

      // Split search query into individual terms
      const searchTerms = normalizedSearch.split(' ');

      const results = data.filter((item:any) => {
        const fullName = `${item?.First_name?.toLowerCase() || ""} ${item?.Last_name?.toLowerCase() || ""}`;
        const searchFields = [
          item?.First_name?.toLowerCase() || "",
          item?.Last_name?.toLowerCase() || "",
          item?.Emp_id?.toLowerCase() || "",
          item?.Band?.toLowerCase() || "",
          item?.Skill_set?.toLowerCase() || "",
          item?.Department?.toLowerCase() || "",
          item?.Active_status?.toLowerCase() || "",
          item?.Secondary_skills?.toLowerCase() || "",
          item?.ThisMonthAllocpercent?.toLowerCase() || "",
          item?.NextMonthAllocpercent?.toLowerCase() || "",
          item?.FutureMonthAllocpercent?.toLowerCase() || "",
        ];

        // Check if all search terms are present in the concatenated name or any search field
        return searchTerms.every(term =>
          fullName.includes(term) ||
          searchFields.some(field => field.includes(term))
        );
      });
      setFilterData(results);
      setFilterTotalCount(results.length);
    }
  }, [nameSearch, data]);

  const convertNum = (MonthAllocpercent: any) => {
    if (MonthAllocpercent === null) {
      return "0.00";
    } else if (MonthAllocpercent === isNaN(MonthAllocpercent)) {
      return "0.00";
    } else {
      return MonthAllocpercent.toFixed(2);
    }
  };

  const getMonthsId = async () => {
    await AxiosConfig.get("financialmonths", {
      headers: { Authorization: `Bearer ${token}` },
    }).then(async (res) => {
      const currentMonth: any = res.data.data.months.filter(
        (month: any) => month.status === "1"
      );
      await getProjectAllocation(currentMonth[0].id);
      setCurrentMonth(currentMonth[0].id);
    });
  };
  const getProjectAllocation = async (monthid: any) => {
    const userId = localStorage.getItem("userId");
    await AxiosConfig.get(`allocations?month_id=${monthid}&user_id=${userId}`, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      setProjectMonth({
        ThisMonth: res.data.data.months[0].ThisMonth,
        NextMonth: res.data.data.months[0].NextMonth,
        FutureMonth: res.data.data.months[0].FutureMonth,
        ThisMonthYear: res.data.data.months[0].ThisMonthYear,
        NextMonthYear: res.data.data.months[0].NextMonthYear,
        FutureMonthYear: res.data.data.months[0].FutureMonthYear,
      });
    });
  };

  const getBands = async () => {
    await AxiosConfig.get("bands", {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        let band = res.data.data.bands;
        if (band.length === 0) {
          setIsLoading(false);
        }
        band = band.sort((a: any, b: any) => {
          let a1 = a.name.toLowerCase();
          let b1 = b.name.toLowerCase();

          if (a1 < b1) {
            return -1;
          } else if (a1 > b1) {
            return 1;
          } else {
            return 0;
          }
        });

        setBands(
          band.map((row: { name: any; id: any }) => ({
            text: row.name,
            value: row.name,
          }))
        );
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getDepatments = async () => {
    await AxiosConfig.get("departments", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let dept = res.data.data.departments;
      dept = dept.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();

        if (a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        } else {
          return 0;
        }
      });
      setDepartments(
        dept.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    });
  };

  const getSkillSet = async () => {
    await AxiosConfig.get("skills", {
      headers: { Authorization: `Bearer ${token}` },
    }).then((res) => {
      let skill = res.data.data.skills;
      skill = skill.sort((a: any, b: any) => {
        let a1 = a.name.toLowerCase();
        let b1 = b.name.toLowerCase();

        if (a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        } else {
          return 0;
        }
      });
      setSkills(
        skill.map((row: { name: any; id: any }) => ({
          text: row.name,
          value: row.name,
        }))
      );
    });
  };
  const getData = async (monthid: any) => {
    if (monthid) {
      await AxiosConfig.get("allocations/alltalents?month_id=" + monthid, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        let telData: any = [];
        res?.data?.data?.talents.map((row: any) => {
          if (checkTalentStatus(row?.date_of_seperation) != "Inactive") {
            telData.push({
              Emp_id: row?.emp_id,
              First_name: row?.firstName,
              Last_name: row?.lastName,
              Talent_id: row?.id,
              Active_status: checkTalentStatus(row?.date_of_seperation),
              date_of_seperation: row?.date_of_seperation,
              Department: row?.department.name,
              Band: row?.band?.name,
              Skill_set: row?.skill?.name,
              Secondary_skills:
              row?.talent_skills?.length > 0
                ? row?.talent_skills?.map((skill:any) => skill.name).join(", ")
                : "No Secondary skills",
              key: row?.id,
              Full_name: row?.firstName + " " + row?.lastName,
              ThisMonthAllocpercent: convertNum(row?.ThisMonthAllocpercent),
              NextMonthAllocpercent: convertNum(row?.NextMonthAllocpercent),
              FutureMonthAllocpercent: convertNum(row?.FutureMonthAllocpercent),
              allocationsThisMonth:
                row?.allocations[0]?.allocated_percentage ?? 0,
              allocations: row?.allocations,
            });
          }
        });
        const sortedData = telData?.sort((a, b) => a?.Full_name.trim()?.toLowerCase()?.localeCompare(b?.Full_name.trim()?.toLowerCase()));
        setFilterData(sortedData);
        setFilterTotalCount(sortedData.length);
        setData(sortedData);
        setIsLoading(false);
      });
    }
  };

  const lastworkingday = (date_of_seperation?: any) => {
    if (date_of_seperation != null) {
      return (
        "Last Working Day:" +
        " " +
        moment(date_of_seperation).format("Do MMMM YYYY")
      );
    } else {
      return "ACTIVE EMPLOYEE";
    }
  };

  interface DataType {}

  const columns: ColumnsType<DataType> = [
    {
      title: "EMPLOYEE ID",
      fixed: "left",
      dataIndex: "Emp_id",
      key: "Emp_id",
      width: 150,
      sorter: (a: any, b: any) => a.Emp_id.localeCompare(b.Emp_id),
    },
    {
      title: "NAME",
      dataIndex: "Full_name",
      key: "Full_name",
      render: (text: any, record: any) => (
        <div>
          <p className="nameTable">
            {record["First_name"] + " " + record["Last_name"]}
          </p>
        </div>
      ),
      sorter: (a: any, b: any) =>
        a.Full_name.trim()
          .toLowerCase()
          .localeCompare(b.Full_name.trim().toLowerCase()),
    },
    {
      title: "BU",
      dataIndex: "Department",
      key: "bu",
      filters: department,
      render: (text: any, record: any) => (
        <p className="buTable">{record["Department"]}</p>
      ),
      onFilter: (value: any, record: any) => record.Department.includes(value),
      sorter: (a: any, b: any) => a.Department.localeCompare(b.Department),
    },
    {
      title: "BAND",
      dataIndex: "Band",
      key: "band",
      filters: bands,
      render: (text: any, record: any) => (
        <p className="bandTable">{record["Band"]}</p>
      ),
      onFilter: (value: any, record: any) => record.Band.includes(value),
      sorter: (a: any, b: any) => a.Band.trim().toLowerCase().localeCompare(b.Band.trim().toLowerCase()),
    },
    {
      title: "SKILL SET",
      dataIndex: "Skill_set",
      key: "skill_set",
      filters: skills,
      render: (text: any, record: any) => (
        <div className="talent-break-word">
          <p className="skillTable">{record["Skill_set"]}</p>
          <div className="secondarySkills">
            {record.Secondary_skills}
          </div>
        </div>
      ),
      onFilter: (value: any, record: any) => record.Skill_set.includes(value),
      sorter: (a: any, b: any) => a.Skill_set.localeCompare(b.Skill_set),
    },
    {
      title: "STATUS",
      dataIndex: "Active_status",
      key: "status",
      className: "Active",
      render: (text: any, record: any) => {
        return (
          <div
            style={{
              color:
                text === "Active"
                  ? "green"
                  : text === "Resigned"
                  ? "red"
                  : text === "Serving Notice period"
                  ? "orange"
                  : "red",
              fontSize: "16px",
            }}
          >
            {record["Active_status"]}
            {record.date_of_seperation && (
              <p id="statusHover">
                <ul>
                  <p>{lastworkingday(record.date_of_seperation)}</p>
                </ul>
              </p>
            )}
          </div>
        );
      },
      filters: [
        { text: "Active", value: "Active" },
        { text: "Resigned", value: "Resigned" },
        { text: "Serving Notice period", value: "Serving Notice period" },
      ],
      onFilter: (value: any, record: any) => {
        return checkTalentStatus(record.date_of_seperation) == value
          ? true
          : false;
      },
    },

    {
      title: "ALLOCATION (IN %)",

      children: [
        {
          title:
            String(projectMonth.ThisMonth).substring(0, 3) +
            " " +
            projectMonth.ThisMonthYear,
          align: "right",
          dataIndex: "ThisMonthAllocpercent",
          key: "ThisMonthAllocpercent",
          render: (text: any, record: any) => {
            const thisMonthAllocPercent = record["ThisMonthAllocpercent"];

            return (
              <div>
                {thisMonthAllocPercent > 0 ? (
                  <Tooltip
                    title={record?.allocations.map((obj) => {
                      if (obj?.financialmonth_id === currentMonth)
                        return (
                          <li key={obj?.id}>
                            {obj?.project?.name}:{" "}
                            {obj?.allocated_percentage.toFixed(2) + "%"}
                          </li>
                        );
                    })}
                  >
                    <p className="tma showPerDetails">
                      {thisMonthAllocPercent + " %"}
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip title="No allocation found">
                    <p className="tma">{thisMonthAllocPercent + " %"}</p>
                  </Tooltip>
                )}
              </div>
            );
          },
          sorter: (a: any, b: any) =>
            a.ThisMonthAllocpercent - b.ThisMonthAllocpercent,
        },
        {
          title:
            String(projectMonth.NextMonth).substring(0, 3) +
            " " +
            projectMonth.NextMonthYear,
          align: "right",
          dataIndex: "NextMonthAllocpercent",
          key: "NextMonthAllocpercent",
          render: (text: any, record: any) => {
            const nextMonthAllocPercent = record["NextMonthAllocpercent"];

            return (
              <div>
                {nextMonthAllocPercent > 0 ? (
                  <Tooltip
                    title={record?.allocations.map((obj) => {
                      if (obj?.financialmonth_id === currentMonth + 1)
                        return (
                          <li key={obj?.id}>
                            {" "}
                            {obj?.project?.name} :{" "}
                            {obj?.allocated_percentage.toFixed(2) + " %"}
                          </li>
                        );
                    })}
                  >
                    <p className="nma showPerDetails">
                      {record["NextMonthAllocpercent"] + " %"}
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip title="No allocation found">
                    <p className="tma">{nextMonthAllocPercent + " %"}</p>
                  </Tooltip>
                )}
              </div>
            );
          },
          sorter: (a: any, b: any) =>
            a.NextMonthAllocpercent - b.NextMonthAllocpercent,
        },
        {
          title:
            String(projectMonth.FutureMonth).substring(0, 3) +
            " " +
            projectMonth.FutureMonthYear,
          align: "right",
          dataIndex: "FutureMonthAllocpercent",
          key: "FutureMonthAllocpercent",
          render: (text: any, record: any) => {
            const futureMonthAllocpercent = record["FutureMonthAllocpercent"];
            return (
              <div>
                {futureMonthAllocpercent > 0 ? (
                  <Tooltip
                    title={record?.allocations.map((obj) => {
                      if (obj?.financialmonth_id === currentMonth + 2)
                        return (
                          <li key={obj?.id}>
                            {" "}
                            {obj?.project?.name}:{" "}
                            {obj?.allocated_percentage.toFixed(2) + " %"}
                          </li>
                        );
                    })}
                  >
                    <p className="fma showPerDetails">
                      {record["FutureMonthAllocpercent"] + " %"}
                    </p>
                  </Tooltip>
                ) : (
                  <Tooltip title="No allocation found">
                    <p className="tma">{futureMonthAllocpercent + " %"}</p>
                  </Tooltip>
                )}
              </div>
            );
          },
          sorter: (a: any, b: any) =>
            a.FutureMonthAllocpercent - b.FutureMonthAllocpercent,
        },
      ],
    },
  ];

  const talentHeaders = [
    { label: "EMPLOYEE ID", key: "Emp_id" },
    { label: "NAME", key: "Full_name" },
    { label: "BU", key: "Department" },
    { label: "BAND", key: "Band" },
    { label: "SKILL SET", key: "Skill_set" },
    { label: "STATUS", key: "Active_status" },
    {
      label: projectMonth.ThisMonth + "" + projectMonth.ThisMonthYear,
      key: "ThisMonthAllocpercent",
    },
    {
      label: projectMonth.NextMonth + "" + projectMonth.NextMonthYear,
      key: "NextMonthAllocpercent",
    },
    {
      label: projectMonth.FutureMonth + "" + projectMonth.NextMonthYear,
      key: "FutureMonthAllocpercent",
    },
  ];

  const handleOnChange = (event: any) => {
    setNameSearch(event.target.value);
  };

  const cleanAndConvertEmpId = (empId) => {
    // Remove any character that is not a digit
    const cleanedEmpId = empId.replace(/[^0-9]/g, '');
    // Convert the cleaned string to a number
    return parseInt(cleanedEmpId, 10);
  };

  const sortedCSVData = csvData.sort((a:any, b:any) => {
    const aEmpId = cleanAndConvertEmpId(a.Emp_id);
    const bEmpId = cleanAndConvertEmpId(b.Emp_id);
    return aEmpId - bEmpId;
  });
  
  return (
    <div className="table-card">
      <Row>
        <Col md={8} xs={24}>
          <div className="searchForm">
            <form>
              <Input.Search
                allowClear
                onChange={handleOnChange}
                placeholder="Search by name / band / skillset / BU / status"
              />
            </form>
          </div>
        </Col>
        <Col md={8} xs={24}>
          <label className="totalLabel">
            {" "}
            {Buttons.labelForTotal}
            {filterTotalCount}
          </label>
        </Col>
        <Col md={8} xs={24} className="text-end">
          <div className="addTalentBtn"></div>
          <CSVLink
            data={sortedCSVData}
            filename="talents.csv"
            className="exportTableButton"
            title="Export Table"
            headers={talentHeaders}
          >
            <ExportOutlined
              className="exportOultineIcon"
              onClick={() => {
                const arrayWithoutArchieve = currentTableData.current.map(
                  (obj) => ({
                    Emp_id: obj.Emp_id,
                    Full_name: obj.Full_name,
                    Department: obj.Department,
                    Band: obj.Band,
                    Skill_set: obj.Skill_set,
                    Active_status: obj.Active_status,
                    ThisMonthAllocpercent: obj.ThisMonthAllocpercent,
                    NextMonthAllocpercent: obj.NextMonthAllocpercent,
                    FutureMonthAllocpercent: obj.FutureMonthAllocpercent,
                  })
                );
                setCsvData(arrayWithoutArchieve);
              }}
            />
          </CSVLink>
        </Col>
      </Row>

      {isLoading === true ? (
        <Loader />
      ) : (
        <div className="tableOut noWordBreak">
          <Table
            columns={columns}
            dataSource={filterData}
            bordered
            className="talentTable"
            scroll={{ y: 680 }}
            pagination={false}
            onChange={(pagination, filters, sorter, extra) => {
              const filteredData: any = extra.currentDataSource;
              const filteredCount = filteredData.length;
              setFilterTotalCount(filteredCount);
            }}
            summary={(currentData) => {
              currentTableData.current = currentData;
              return <></>;
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TalentAllocation;
