export const CustomerClassification = {
  editConfirmationNote:
    "NOTE: Change of Customer Classification will reflect on the selected Financial Year",
  editConfirmationTitle: "Enter Super Admin Password",
  confirmationPopupTitle:
    " Do you want to apply changes on customer classification ?",
};

export const getMonthlySlots = {
  confirmationNote: "Note: once changed this cannot be reverted",
  labelForSuperSecretPassword: "Enter the super secret password to continue",
};

export const AddCustomer = {
  labelForCustomerName: "Customer Name",
  labelForCustomerCategory: "Customer Category",
  labelForCustomerRelation: "Customer Relation",
  labelForDescription: "Description",
  labelForMSA: "MSA Available", //  Master Service Agreement
  labelForMSA_Desc: "MSA File References", // Master Service Agreement file reference name
};

export const EditCustomer = {
  labelForCustomerName: "Customer Name",
  labelForCustomerCategory: "Customer category",
  labelForCustomerRelation: "Customer Relation",
  labelForDescription: "Description",
  labelForMSA: "MSA Available", //  Master Service Agreement
  labelForMSA_Desc: "MSA File References", // Master Service Agreement file reference name
};

export const ChangePassword = {
  labelForOldPassword: "Old Password",
  labelForNewPassword: "New Password",
  labelForConfirmNewPassword: "Confirm New Password",
};

export const EditCreditNote = {
  labelForCustomerName: "Customer Name",
  labelForCreditAmount: "Credit Amount",
  labelForDescription: "Description",
  labelForAttachment: "Attachment",
  buttonForAdjustAgainstPO: "Adjust against PO",
  totalPercent: "Total Adjusted -",
  calculation: "Adjusted / Pending",
  labelForProjectName: "Project Name:",
  labelForInvoiceAmount: "Final Invoice:",
  labelForInvoiceDescription: "Description:",
  labelForTotal: "Total",
  labelForIssueDate: "Issue Date",
};

export const AddProject = {
  labelForCustomerName: "Customer Name",
  labelForProjectName: "Project Name",
  labelForAssociatedBU: "Associated BU ",
  labelForProjectAccess: "Project Access",
  labelForBillableorNonBillable: "Billable/Non-Billable",
  labelForBillableOption: "Billing Entity",
  labelForGeography: "Geography",
  labelForProjectOwner: "Project Owner",
  ButtonForUpload: "Upload",
};

export const EditProject = {
  labelForCustomerName: "Customer Name",
  labelForProjectName: "Project Name",
  labelForProjectCode: "Project Code",
  labelForAssociatedBU: "Associated BU ",
  labelForProjectManagers: "Project Access",
  labelForBillableorNonBillable: "Billable/Non-Billable",
  labelForBillableOptions: "Billing Entity",
  labelForGeoLocation: "Geo Location",
  labelForAttachments: "Attachments",
  labelForProjectOwner: "Project Owner",
};

export const dragList = {
  labelForCustomerClassHeading: "Welcome to Customer Classification",
  labelForFinancialYear: "Financial Year",
};

export const Buttons = {
  labelForAddUserButton: "Add User",
  labelForBackButton: "BACK",
  labelForRecordDataButton: "Record Data",
  labelForUpload: "Upload",
  labelForTotal: "Total #:",
  textForTotalAvailableHrs: "Total available hrs:",
  headingForMonthSlots: "Month Slots",
};

export const editUser = {
  labelForFirstName: "First Name",
  labelForLastName: "Last Name",
  labelForPassword: "Password",
  labelForPlaceHolder:
    "To change the password, enter new value. Else leave blank to retain old password.",
  labelForDepartments: "Access to Departments",
};

export const addUser = {
  labelForFirstName: "First Name",
  labelForLastName: "Last Name",
  labelForEmailId: "Email ID",
  labelForPassword: "Password",
  labelForDepartments: "Access to Departments",
};

export const unavailableRecords = {
  labelForMonth: "Month",
  labelForDepartments: "Department",
  labelForSkill: "Skill",
  labelForBand: "Band",
  labelForHours: "Hours",
  labelForAvailableHours: "Hours / Available Hours",
  labelForNotes: "Notes",
};

export const talentDetailsCard = {
  labelForBand: "Band",
  labelForLifetimeSTUs: "Lifetime STUs",
  labelForAssignedBu: "Assigned BU",
  labelForJoiningDate: "Joining Date",
  labelForRelievingDate: "Relieving Date",
  labelForSkill: "Skills",
  labelForStatus: "Status",
  labelForSalesPerson: "Sales Person",
  labelForCapacityReport: "Capacity Report",
};

export const skillRecord = {
  labelForExistingSkill: "Existing Primary Skill",
  labelForNewSkills: "New Primary Skill",
  labelForDate: "Date",
  labelForNotes: "Notes",
};

export const resignationRecord = {
  labelForLastWorkingDay: "Last Working Day",
  labelForNotes: "Notes",
};

export const recordData = {
  headingForSelectingRecord: "Please select a record",
  headingForComingSoon: "Coming Soon...",
};

export const projectByHour = {
  headingForActiveProject: "Active Projects",
  optionFor30: "Last 30 Days",
  optionFor90: "Last 90 Days",
  optionFor365: "Annual",
};

export const departmentRecord = {
  labelForExistingDepartment: "Existing Department",
  labelForNewDepartment: "New Department",
  labelForNotes: "Notes",
};

export const bandRecord = {
  labelForExistingBand: "Existing Band",
  labelForNewBand: "New Band",
  labelForNotes: "Notes",
};

export const approvedHours = {
  headingForBillableNonbillableNotApproved:
    "Billable / Non-Billable / Not Approved",
  optionFor30: "Last 30 Days",
  optionFor90: "Last 90 Days",
  optionFor365: "Annual",
};

export const topUtilised = {
  headingForPerformanceCard: "Top 10",
  optionFor30: "Last 30 Days",
  optionFor90: "Last 90 Days",
  optionFor365: "Annual",
};

export const snapshot = {
  labelFor30Utilization: "30 days Utilization",
  labelFor90Utilization: "90 days Utilization",
};

export const bottomUtilised = {
  headingForPerformanceCardBottom: "Bottom 10",
  optionFor30: "Last 30 Days",
  optionFor90: "Last 90 Days",
  optionFor365: "Annual",
};

export const attrition = {
  headingForAttrition: "Attrition",
  labelForAnnual: "Annual",
  labelFor90: "90 Days",
  labelFor30: "30 Days",
  headingForGrowth: "Growth",
};

export const addTalent = {
  labelForFirstName: "First Name",
  labelForLastName: "Last Name",
  labelForEmailId: "Email ID",
  labelForEmployeeId: "Employee ID",
  labelForDateofJoining: "Date of Joining",
  labelForBand: "Band",
  labelForPrimarySkill: "Primary Skill Set",
  labelForDepartment: "Department",
};

export const soldSummary = {
  labelForSoldSummary: "Sold Summary",
  labelForLifetimeStus: "Lifetime STUs",
  labelForLifetimeSales: "Lifetime Sales",
  labelForAvgSoldbyStu: "Sold Avg / STU",
};

export const snapshotDetail = {
  textForRecognized: "Recognized",
  textForGained: "Gained",
  textForDropped: "Dropped",
  textForEarnedStu: "Earned STUs",
  textForPlannedStu: "Planned STUs",
  textForUtilizedStu: "Utilized STUs",
  textForPlannedEfficiency: "Planned Efficiency",
  textForActualEfficiency: "Actual Efficiency",
  textForEarningbyStus: "Earning / Stus",
};

export const renderMarkasSent = {
  labelForFinalInvoiceAmt: "Final Invoice Amount",
  labelForInvoiceDate: "Invoice Date",
  labelForInvoiceNumber: "Invoice Number",
  labelForAttachments: "Attachments",
};

export const recordRevenue = {
  labelForUpload: "Upload",
  labelForValue: "Value % ",
  labelForNotes: "Notes",
  labelForAttachments: "Attachments",
  labelForTotalClaimed: "Total Claimed",
  labelForCurrentFinancialMonth: "Current Financial Month:",
  textForDeletingCurrentMonth:
    "Are you sure, you want to delete current month records?",
  textForActualValue: "Actual Value:",
  textForEarnedStu: "Earned STUs",
  textForDirectExpenses: "Actual Direct Non/STU Expenses",
  textForProjectedDirectExpenses: "Projected Direct Non/STU Expenses",
};

export const recordRetainer = {
  labelForUpload: "Upload",
  labelForEarned: "Earned",
  labelForConversionRate: "Conversion Rate:",
  labelForDropped: "Dropped",
  labelForGained: "Gained",
  labelForStusEarned: "Stus Earned",
  labelForNotes: "Notes",
  labelForAttachments: "Attachments",
  textForDeleteModal: "Are you sure, you want to delete current month records?",
  labelForCurrentFinancialMonth: "Current Financial Month:",
  labelForMaxClaimable: "Max Claimable:",
  textForDirectExpenses: "Actual Direct Non/STU Expenses",
  textForProjectedDirectExpenses: "Projected Direct Non/STU Expenses",
};

export const purchaseOrderTable = {
  labelForPurchaseOrder: "Purchase Order",
  ButtonForAddPO: "Add PO",
  textForLoadingContent: "Loading content please wait...",
  textForNotApplicable: "Not Applicable",
  textForNoPO: "No PO Found",
};

export const purchaseOrderList = {
  warningTextForPoServices: "PO Services has not been allocated",
  labelForTotal: "Total",
  textForTotal: "Total #:",
};

export const projectDetails = {
  textForCurrentValue: "Value:",
  textForFutureValue: "Future Value:",
  ButtonForProjectAllocationDetail: "Project allocation details",
  textForBack: "BACK",
};

export const poServiceTableInfo = {
  buttonForSubmit: "Submit",
  buttonForUpdate: "Update",
  textFortotalper: "Total % :",
};

export const po = {
  textForDeleteModal: "Are you sure, you want to delete this Purchase Order?",
  textForNote:
    "Note - All earned values will be updated based on the percentage recognized each month with the new PO value",
  textForNotAssigned: "Not assigned",
  headingForTotalPer: "Total %:",
  labelForPercentage: "Percentage (%)",
  labelForSalesPerson: "Sales Person",
  textForSalesAttribution: "Sales Attribution",
  labelForAttachments: "Attachments",
  labelForPoDesc: "PO Description",
  labelForDirectandNonbyStuExpense: "Direct Non/STU Expenses",
  labelForProjectedDirectExpense: "Projected Expense",
  labelForStusold: "STUs Sold",
  labelForSoldbyStu: "Sold/STU",
  labelForConversionRate: "Conversion Rate:",
  labelForValueSold: "Value Sold",
  labelForPoTypes: "PO Type",
  labelForSaleDate: "Sale Date",
  labelForPoName: "PO Name",
  labelForPoStatus: "PO Status",
  labelForPoNumber: "PO Number",
  textForPercentage: "Percentage",
  labelForCustomerRelation: "Customer Relation",
  labelForCustomer: "Customer",
  textNote: "Note: once changed this cannot be reverted",
  labelForSuperSecretPass: "Enter the super secret password to continue",
};

export const modal = {
  buttonForCancel: "Cancel",
  buttonForDelete: "Delete",
  textForDeleteWarning: "Are you sure want to Delete?",
};

export const markAsSend = {
  warningTextForPoStatus: "Do you want to change the PO status to PO Invoiced?",
  labelForAttachments: "Attachments",
  labelForRecordedDate: "Recorded Date",
  labelForConversionRate: "Conversion Rate:",
  labelForFinalAmount: "Final Amount",
  labelForInvoiceNumber: "Invoice Number",
  buttonForUpload: "Upload",
};

export const invoiced = {
  labelForDesc: "Description",
  labelForExpectedDate: "Expected Date",
  textForEquivalentValue: "Equivalent value",
  labelForInvoiceAmt: "Invoice Amount",
  buttonForAddInvoice: "Add Invoice",
  labelForPlannedInvoice: "Planned Invoice",
  labelForInvoiceSent: "Invoiced Sent",
  textForTotalInvoiceable: "Total invoiceable-",
  labelForCreditNote: "Credit Note",
};

export const efficiencySummary = {
  labelForEfficiency: "Efficiency",
  labelForUtilizedStus: "Utilized STUs",
  labelForEarnedStus: "Earned STUs",
  headingForDeliveryEfficiency: "Delivery Efficiency",
};

export const efficiencyList = {
  popoverLabelForActualEfficiency: "Actual Efficiency",
  popoverLabelForPlannedEfficiency: "Planned Efficiency",
};

export const editRevenueRecord = {
  warningTextForDeletingRecord:
    "Are you sure, you want to delete current month records?",
  labelForAttachments: "Attachments",
  labelForValuePer: "Value %",
  textForProjectedDirectExpenses: "Projected Direct Non/STU Expenses",
};

export const editInvoice = {
  labelFOrInvoiceDesc: "Invoice Description",
  labelForInvoiceTitle: "Invoice Title",
  labelForExpectedDtae: "Expected Date",
  labelForConversionRate: "Conversion Rate:",
  labelForInvoiceAmt: "Invoice Amount",
};

export const earnedSummary = {
  labelForEarnedAvgbyStu: "Earned Avg / STU",
  labelForEarnedRevenue: "Earned Revenue",
  labelForUtilizedStu: "Utilized STUs",
  headingForEarnedSummary: "Earned Summary",
};

export const deletepurchaseOrderModal = {
  buttonForCancel: "Cancel",
  buttonForDelete: "Delete",
  warningMsgForDelete: "Are you sure want to Delete?",
};

export const basicInfo = {
  labelForAttachment: "Attachments",
  textForProjectOwner: "Project Owner",
  textForGeography: "Geography",
  textForBillable: "Billable",
  textForBillableOption: "Billing Entity",
  textForProjectManager: "Project Access",
  textForDepartment: "Department",
  textForCustomer: "Customer",
  textForProjectCode: "Project Code",
  textForRoles: "Roles",
  textForProjectAccess: "Project Access",
};

export const addPurchaseOrder = {
  labelForPercentage: "Percentage (%)",
  labelForSalesPerson: "Sales Person",
  textForSalesAttribution: "Sales Attribution",
  headingForTotalPer: "Total %:",
  labelForPoDesc: "PO Description",
  labelForDirectandNonbyStuExpense: "Direct Non/STU Expenses",
  labelForConversionRate: "Conversion Rate:",
  labelForValueSold: "Value Sold",
  labelForStuSold: "STUs Sold",
  labelForPoType: "PO Type",
  labelForSaleDate: "Sales Date",
  labelForPoName: "PO Name",
  labelForPoStatus: "PO Status",
  labelForPoNumber: "PO Number",
  buttonForUpload: "Upload",
};

export const addInvoice = {
  labelFOrInvoiceDesc: "Invoice Description",
  labelForInvoiceTitle: "Invoice Title",
  labelForExpectedDtae: "Expected Date",
  labelForConversionRate: "Conversion Rate:",
  labelForInvoiceAmt: "Invoice Amount",
};

export const project = {
  textForTotal: "Total",
  buttonForAddProject: "Add Project",
  labelForGeography: "Geography",
  labelForTotal: "Total #:",
};

export const addModal = {
  buttonForCancel: "Cancel",
  buttonForArchive: "Archive",
  buttonForUnarchive: "Unarchive",
  warningMsgForArchive: "Are you sure want to Archive?",
  warningMsgFOrUnarchive: "Are you sure want to Unarchive?",
  buttonForDelete: "Delete",
  warningMsgForDelete: "Are you sure want to Delete?",
};

export const allocations = {
  textForTotal: "Total",
  labelForTotal: "Total #:",
};

export const projectStuSummary = {
  textForEarned: "Earned",
  textForPlanned: "Planned",
  textForStusSold: "STUs Sold",
  headingForProjectStuSummary: "Project STU Summary",
};

export const manageTalent = {
  labelForTalentName: "Talent Name",
  labelForpercent: "%",
  labelForHrs: "hrs",
};

export const editAllocation = {
  labelForTalentName: "Talent Name",
  labelForpercent: "%",
  labelForHrs: "hrs",
};

export const allocationDetails = {
  textForTotal: "Total",
  headingForTalentAllocation: "Talent Allocation",
  buttonForManageTalent: "Manage Talent",
  buttonForProjectDetails: "Project Details",
  backButton: "BACK",
  buttonCloneFrom: "Clone from",
  buttonConfirmClone: "Confirm",
  buttonCancel: "Cancel",
  buttonCloneTo: "Clone to :",
};

export const viewMonthsData = {
  headingForAddForexRate: "Add Forex Rate",
  headingForForexRates: "Forex rates against the base currency",
  headingForBaseCurrency: "Base currency is",
  headingForMonthsYear: "Month Year",
  headingForMonthName: "Month Name",
};

export const editMonthsData = {
  buttonForCancel: "Cancel",
  buttonForSave: "Save",
  radioButton: "Only update this month with this rate",
  radioButtonFuture: "Update all future months with this rate",
  warningMsgToSave: "Are you sure want to save?",
  headingForAddForexRate: "Add Forex Rate",
  headingForForexRates: "Forex rates against the base currency",
  headingForBaseCurrency: "Base currency is",
  headingForMonthsYear: "Month Year",
  headingForMonthName: "Month Name",
};

export const customer = {
  textForTotal: "Total",
  buttonForAddCustomer: "Add Customer",
  labelForCategory: "Category",
  labelForTotal: "Total #:",
};

export const app = {
  headingForTalent: "Talent",
  menuInvoice: "Invoice",
  menuSales: "Sales",
  menuDelivery: "Delivery",
  headingForProject: "Projects",
  menuProject: "Projects",
  menuPurchaseOrder: "Purchase Orders",
  menuSnapShot: "Snapshot",
  menuForExpenses: "Expenses",
  menuForAudits: "Audit",
  menuTrends: "Trends",
  menuTalentList: "Talent List",
  headingForAllocation: "Allocations",
  menuProjectAllocation: "Project Allocation",
  menuTalentAllocation: "Talent Allocation",
  menuUsers: "Users",
  menuMonthSlots: "Month Slots",
  menuAuditReview: "Review Setting",
  menuChangePass: "Change Password",
  menuTrainAIModal: "Train AI Modal",
  menuLogout: "Logout",
  headingForSnapshotRepaort: "Snapshot Report",
  headingForAuditReport: "Reports",
  headingForProjectAllocation: "Project Allocation",
  headingForTalentAllocation: "Talent Allocation",
  headingForTalentAllocationDetails: "Talent Allocation Details",
  headingForProjectDetails: "Project Details",
  headingForExpenses: "Expenses",
  headingForAudits: "Audit",
  AuditstextForBack: "BACK",
  headingForSummary: "Summary",
  headingForClassification: "Classification",
  headingForCreditNote: "Credit Note",
  headingForTalentDetails: "Talent Details",
  headingForProjectRevenueRecognition: "Project Revenue Recognition",
  headingForPurchaseOrder: "Purchase Orders",
  headingForChangePass: "Change Password",
  headingForMonthSlot: "Month Slots",
  hedingForAuditReview: "Review Settings",
  headingForSales: "Sales",
  headingForRevenueRecognition: "Revenue Recognition",
  headingForUsers: "Users",
  headingForDeliveryDashboard: "Delivery Dashboard",
  headingForSalesDashboard: "Sales Dashboard",
  headingForInvoiceDashboard: "Invoice Dashboard",
  headingForInvoiceDetail: "Invoice Details",
  hello: "Hello",
  menuDashboard: "Dashboard",
  menuCustomer: "Customers",
  menuMore: "More",
};

export const enumForSideMenu = {
  menuItemSales: "Sales",
  menuItemDelivery: "Delivery",
  menuItemInvoice: "Invoice",
  menuItemSnapshot: "Snapshot",
  menuItemProjects: "Projects",
  menuItemPurchaseOrder: "Purchase Orders",
  menuItemProjectAllocation: "Project Allocation",
  menuItemTalentAllocation: "Talent Allocation",
  menuItemSummary: "Summary",
  menuItemClassification: "Classification",
  menuItemCreditNote: "Credit Note",
  menuItemTrends: "Trends",
  menuItemTalentList: "Talent List",
  menuItemUsers: "Users",
  menuItemExpenses: "Expenses",
  menuItemAudit: "Audit",
  menuItemReviews: "Reviews",
  menuItemReports: "Reports",
  menuItemSettings: "Settings",
  menuItemAI: "AI Assistance",
};

export const enumForCreditNote = {
  textForTotal: "Total",
  buttonForAddCreditNote: "Add Credit Note",
  textForTotalAmount: "Total Credit Amount :",
  textForPendingAmount: "Total Unaccounted Credit Amount :",
};

export const enumForSalesAttribution = {
  textForSalesAttribution: "Sales Attribution",
  labelForTotalper: "Total%:",
  textForSalesPerson: "Sales Person",
  textForPercentage: "Percentage",
  labelForNotAssigned: "Not Assigned",
  textForSalesPercentage: "Sales Percentage",
  textForNote: "Note - Sales Person will be Updated",
  textForDeleteModal:
    "Are you sure, you want to delete this Sales Attribution?",
};

export const enumForAddCreditNote = {
  labelForCustomerName: "Customer Name",
  labelForCreditNoteNumber: "Credit Note #",
  labelForCreditAmount: "Credit Amount",
  labelForDescription: "Description",
  labelForIssueDate: "Issue Date",
};

export const enumForBuDashboardDeliveryDetailView = {
  labelForRevenueReport: "Revenue report",
  labelForStusUtilized: "STUs utilised",
};

export const enumForExpensesDrawer = {
  labelForExpense: "Expenses",
  labelForTypeOfExpense: "Type of Expense",
  labelForFinancialMonth: "Financial Month",
  labelForAdjustedAmount: "Adjusted Amount",
  labelForCurrencySymbol: "₹",
  labelForNoExpenses: "No Expenses",
  labelForRefernceNo: "Reference No",
  labelForVendorName: "Vendor Name",
  labelForBillDate: "Bill Date",
  labelForNotes: "Notes",
  labelForTotalAdjusted: "Total Adjusted Amount :",
  labelForMonthYear: "Month & Year"
};
export const enumForExpenses = {
  labelForTotalAmount: "Total Amount : ",
  buttonForAddExpense: "Add Expenses",
  labelForTotalUnaccountedAmount: "Total Unaccounted Amount : ",
};

export const enumForAddExpense = {
  labelForReferenceNo: "Reference No.",
  labelForVendorName: "Vendor",
  labelForBillDate: "Bill Date",
  labelForAmount: "Amount (In ₹)",
  labelForTypesExpenses: "Type of Expense",
  labelForNotes: "Notes",
};

export const enumForEditExpenseDetailsSection = {
  labelForTotal: "Total",
  buttonForAdjustAgainstPO: "Adjust against PO",
  calculation: "Adjusted / Pending",
  totalPercent: "Total Adjusted -",
  EarnedValue: "Earned Value :",
  Notes: "Notes :",
  currencySymbol: "₹",
  labelForNoOption: "No Options Available",
  labelforDoneButton: "Done",
  labelForWarningTitle: "Warning",
  labelForYes: "Yes",
  labelForCancel: "Cancel",
  warningMessage:
    "The expense amount is greater than the earned value of the selected PO. Do you want to continue?",
};


export const enumForAudit = {
  labelForProjectName: "Project Name",
  labelForProjectCode: "Project Code",
  labelForPojectCount: "Total Projects",
  labelForCustomersCount: "Total Customers",
  labelForCustomerName: "Customer Name"
}

export const deleteModal = {
  buttonForCancel: "Cancel",
  buttonForDelete: "Delete",
  warningMsgForDelete: "Are you sure want to Delete?",
};

export const rolesEnums = {
  warningMsgForDelete: "Are you sure you want to delete this role?",
};